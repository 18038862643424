<ngx-spinner></ngx-spinner>
<router-outlet></router-outlet>

<div class="modal fade" id="globalErrorModal" tabindex="-1" role="dialog"
	aria-labelledby="affiliateInstructionsModal" aria-hidden="true" style="padding-left: 0;">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body text-center">
				<p *ngFor="let error of errors | keyvalue" class="text-danger"
					[innerHtml]="error.value">
				</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn button2" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>
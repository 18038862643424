<ngx-spinner></ngx-spinner>
<div class="container">
	<div class="wrp-cntr">
		<div id="card" class="d-flex flex-column justify-content-center align-items-center">
			<p class="text-center header m-0">
				{{ error ? 'Error !' : 'Email Confirmation'}}
			</p>
			<hr class="org-ln" />
			<div class="img-eml mt-4 mb-3">
				<img src="assets/images/error.png" *ngIf="error" />
				<img src="assets/images/email.png" *ngIf="!error" />
			</div>
			<div class="my-3">
				<p class="text-center text-content font-weight-bolder">
					{{ text_message }}
				</p>
			</div>
			<div id="next-navgtr-button" class="my-2">
				<button class="custom-filled-button" (click)="navigateToSteps()">
					Save <span> and Refresh Phone </span>
				</button>
			</div>
		</div>
	</div>
</div>
<!-- <div class="innerpage_wrapper">
	<section class="safety_wrapper">
		<div class="container">
			<div class="email-confirmation">
				<h2>Email Confirmation</h2>
				<hr>
				<img alt="" src="assets/images/email.png" />
				<p class="text-center mb-0 mt-2">
					{{outputMessage}}
				</p>
				<button (click)="navigateToSteps()" class="custom-btn savebutton mt-4">
					SAVE AND REFRESH PHONE
				</button>
			</div>

		</div>
	</section>
</div> -->
import { AfterViewChecked, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { StateManagementService } from '../../../services/statemanagement.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ErrorDialogService } from 'src/app/services/error-dialog/errordialog.service';
import { CommonService } from 'src/app/services/common.service';

declare var $: any;
@Component({
  selector: 'app-add-vehicle-subscriber',
  templateUrl: './add-vehicle-subscriber.component.html',
  styleUrls: ['./add-vehicle-subscriber.component.scss']
})
export class AddVehicleSubscriberComponent implements OnInit {
  
  public tree: any;
	public affiliateId: string;
	public affiliateType: string;
	public paramResponse: any;
	public vehicleTypeId: string;
	public imageSrc: string;
	public vehicleImageArray: Array<object> = [];
	public stepCompleted: string;
	public selectedDefaultModel: String;

	public addVehicleForm: FormGroup;
	public submittedForm: boolean;
	public disableSubmitButton: boolean = false;
	public vehicleSettingProgressBar: boolean = false;
	public response: any;
	public year: Array<object>;
	public filteredYear: Array<object>;
	public make: Array<any>;
	public filteredMake: Array<object>;
	public model: Array<any>;
	public filteredModel: Array<any>;
	public vehicleTypes: Array<object>;
	public filteredVehicleTypes: Array<object>;
	public color: Array<object>;
	public filteredColor: Array<object>;
	public chargableAmenities: object;
	public nonChargableAmenities: object;
	public specialAmenities: object;
	public interiors: object;
	public allModels = JSON.parse(sessionStorage.getItem('models'));

	public vehicleImage1: string;
	public vehicleImage2: string;
	public vehicleImage3: string;
	public vehicleImage4: string;
	public vehicleImage5: string;
	public vehicleImage6: string;
	public vehicleImage7: string;
	public vehicleImage8: string;
	public vehicleImage9: string;

	public oldvehicleImage: any = [];

	public vehicleImageId1: string;
	public vehicleImageId2: string;
	public vehicleImageId3: string;
	public vehicleImageId4: string;
	public vehicleImageId5: string;
	public vehicleImageId6: string;
	public vehicleImageId7: string;
	public vehicleImageId8: string;
	public vehicleImageId9: string;

	public rearPlateImage: string;
	public windowPermitImage: string;
	public windowPermit2Image: string;
	public usdotPermitImage: string;
	public mcImage: string;


	public rearPlateId: string;
	public windowPermitId: string;
	public windowPermit2Id: string;
	public usdotPermitId: string;
	public mcId: string;
	public modalImage: string;
	public luggageOptions: any = [];
	public seatOptions: any = [];
	public charterCancelOptions: Array<Object>;
	public nonCharterCancelOptions: Array<Object>;
	public serviceType: string;
	isVehicleTypeSelected: boolean = false


	@Input() closeTab: EventEmitter<any> = new EventEmitter();
	errorMsg: boolean;
	errorMsg1: boolean;
	errorMsg2: boolean;
	errorMsg3: boolean;
	errorMsg4: boolean;
	constructor(
		private adminService: AdminService,
		private router: Router,
		private stateManagementService: StateManagementService,
		private formBuilder: FormBuilder,
		private spinner: NgxSpinnerService,
		private activatedroute: ActivatedRoute,
		private httpClient: HttpClient,
		private commonServices: CommonService,
		private errorModal: ErrorDialogService) { }

	ngAfterViewChecked() {
		$(".camera-svg").tooltip({
			trigger: 'hover'
		});
		$(".camera-svg").on('mouseleave', function () {
			$(this).tooltip('dispose');
		});
		$(".camera-svg").on('click', function () {
			$(this).tooltip('dispose');
		});
		$(".backbutton").tooltip({
			trigger: 'hover'
		});
		$(".backbutton").on('mouseleave', function () {
			$(this).tooltip('dispose');
		});
		$(".backbutton").on('click', function () {
			$(this).tooltip('dispose');
		});
	}

	ngOnInit(): void {
		$('#vehicleTypeField').focusout(() => {
			this.errorMsg = true;
		})
		$('#makeField').focusout(() => {
			this.errorMsg1 = true;
		})
		$('#modelField').focusout(() => {
			this.errorMsg2 = true;
		})
		$('#yearField').focusout(() => {
			this.errorMsg3 = true;
		})
		$('#colorField').focusout(() => {
			this.errorMsg4 = true;
		})
		//pick vehicle type id from query params
		this.activatedroute.queryParamMap
			.subscribe((params) => {
				console.log('params--->>>', params)
				this.paramResponse = { ...params.keys, ...params };
				this.vehicleTypeId = this.paramResponse.params.vehicleTypeId;
			}
			);
		this.httpClient.get("assets/json/charterOptions.json").subscribe((data: any) => {
			this.nonCharterCancelOptions = data;
			this.charterCancelOptions = data;
		});

		//data for dropdown of seats and luggage
		for (let i = 2; i <= 75; i++) {
			this.luggageOptions.push(i);
		}
		for (let i = 4; i <= 75; i++) {
			this.seatOptions.push(i);
		}

		// this.affiliateId = sessionStorage.getItem("affiliateId");
		this.affiliateId = JSON.parse(localStorage.getItem("currentUser"))?.account_id
		// this.stepCompleted = sessionStorage.getItem("stepCompleted");

		sessionStorage.setItem('vehicleTypeId', this.vehicleTypeId);

		//add amenity form validation
		this.addVehicleForm = this.formBuilder.group({
			acc_id: [''],
			vehicleType: ['', Validators.required],
			make: ['', Validators.required],
			model: ['', Validators.required],
			year: ['', Validators.required],
			color: ['', Validators.required],
			licensePlate: [''],
			numberOfVehicles: [1],
			seats: [4, [Validators.required, Validators.pattern("^[0-9]*$")]],
			luggage: [2, [Validators.required, Validators.pattern("^[0-9]*$")]],
			charterCancelPolicy: ['2', Validators.required],
			nonCharterCancelPolicy: ['2', Validators.required],
			typeOfService: this.formBuilder.array([], [Validators.required]),
			amenities: this.formBuilder.array([], [Validators.required]),
			specialAmenities: this.formBuilder.array([]),
			vehicleInterior: this.formBuilder.array([], [Validators.required]),
			vehicle_image_1: ['', Validators.required],
			vehicle_image_2: [''],
			vehicle_image_3: [''],
			vehicle_image_4: [''],
			vehicle_image_5: [''],
			vehicle_image_6: [''],
			vehicle_image_7: [''],
			vehicle_image_8: [''],
			vehicle_image_9: [''],
			rearPlateImage: [''],
			windowPermitImage: [''],
			windowPermit2Image: [''],
			usdotPermitImage: [''],
			mcImage: [''],
		});
		//Put Black color value by default in Color
		let colorField: any = document.getElementById('colorField');
		colorField.value = "Black";
		this.addVehicleForm.patchValue({
			color: 1,
		});

		this.affiliateType = sessionStorage.getItem("affiliateType");
		if (this.affiliateType != 'fleet_operator') {
			console.log("in if setting lecesne plate")
			// this.addVehicleForm.controls['licensePlate'].setValidators([Validators.required]);
			// this.addVehicleForm.controls['licensePlate'].updateValueAndValidity();
			// this.addVehicleForm.controls['rearPlateImage'].setValidators([Validators.required]);
			// this.addVehicleForm.controls['rearPlateImage'].updateValueAndValidity();
		}

		// this.stateManagementService.getNumberOfVehicles().subscribe(numberOfVehicles => {
		// 	let numberOfVehiclesCanBeAdded;
		// 	if (this.affiliateType == 'fleet_operator') {
		// 		this.addVehicleForm.controls['numberOfVehicles'].setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
		// 	}
		// 	else if (this.affiliateType == 'black_limo_operator') {
		// 		numberOfVehiclesCanBeAdded = 2 - numberOfVehicles;
		// 		this.addVehicleForm.controls['numberOfVehicles'].setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1), Validators.max(numberOfVehiclesCanBeAdded)]);
		// 	}
		// 	else {
		// 		numberOfVehiclesCanBeAdded = 1 - numberOfVehicles;
		// 		this.addVehicleForm.controls['numberOfVehicles'].setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1), Validators.max(numberOfVehiclesCanBeAdded)]);
		// 	}
		// 	this.addVehicleForm.controls['numberOfVehicles'].updateValueAndValidity();
		// });

		/** spinner starts on init */
		this.spinner.show();
		// Load Our amenities using API
		this.adminService.adminAffiliateGetFieldsData()
			.pipe(
				catchError(err => {
					this.spinner.hide();
					return throwError(err);
				})
			).subscribe(result => {
				this.response = result;
				this.filteredYear = this.year = this.response.data.years;
				this.filteredMake = this.make = this.response.data.make;
				this.filteredModel = this.model = this.response.data.model;
				this.filteredVehicleTypes = this.vehicleTypes = this.response.data.vehicle_types;
				this.filteredColor = this.color = this.response.data.color;
				this.chargableAmenities = this.response.data.chargableAmenities;
				this.nonChargableAmenities = this.response.data.nonChargableAmenities;
				this.specialAmenities = this.response.data.specialAmenities;
				this.interiors = this.response.data.vehicleInterior;
				const vehicleInterior: FormArray = this.addVehicleForm.get('vehicleInterior') as FormArray;
				vehicleInterior.push(new FormControl('1'));
				sessionStorage.setItem('models', JSON.stringify(this.model));
				console.log("<><><><><><><><><><><><><><><><><><>", this.response.data)

				this.vehicleImage1 = this.oldvehicleImage[0] = this.response.data.vehicleImage1.image;
				this.vehicleImage2 = this.oldvehicleImage[1] = this.response.data.vehicleImage2.image;
				this.vehicleImage3 = this.oldvehicleImage[2] = this.response.data.vehicleImage3.image;
				this.vehicleImage4 = this.oldvehicleImage[3] = this.response.data.vehicleImage4.image;
				this.vehicleImage5 = this.oldvehicleImage[4] = this.response.data.vehicleImage5.image;
				this.vehicleImage6 = this.oldvehicleImage[5] = this.response.data.vehicleImage6.image;
				this.vehicleImage7 = this.oldvehicleImage[6] = this.response.data.vehicleImage7.image;
				this.vehicleImage8 = this.oldvehicleImage[7] = this.response.data.vehicleImage8.image;
				this.vehicleImage9 = this.oldvehicleImage[8] = this.response.data.vehicleImage9.image;

				this.vehicleImageId1 = this.response.data.vehicleImage1.id;
				this.vehicleImageId2 = this.response.data.vehicleImage2.id;
				this.vehicleImageId3 = this.response.data.vehicleImage3.id;
				this.vehicleImageId4 = this.response.data.vehicleImage4.id;
				this.vehicleImageId5 = this.response.data.vehicleImage5.id;
				this.vehicleImageId6 = this.response.data.vehicleImage6.id;
				this.vehicleImageId7 = this.response.data.vehicleImage7.id;
				this.vehicleImageId8 = this.response.data.vehicleImage8.id;
				this.vehicleImageId9 = this.response.data.vehicleImage9.id;

				this.rearPlateImage = this.oldvehicleImage[9] = this.response.data.rear_plate.image;
				this.windowPermitImage = this.oldvehicleImage[10] = this.response.data.window_permit.image;
				this.windowPermit2Image = this.oldvehicleImage[11] = this.response.data.window_permit_1.image;
				this.usdotPermitImage = this.oldvehicleImage[12] = this.response.data.USDOT_permit.image;
				this.mcImage = this.oldvehicleImage[13] = this.response.data.mc.image;

				this.rearPlateId = this.response.data.rear_plate.id;
				this.windowPermitId = this.response.data.window_permit.id;
				this.windowPermit2Id = this.response.data.window_permit_1.id;
				this.usdotPermitId = this.response.data.USDOT_permit.id;
				this.mcId = this.response.data.mc.id;

				//get models as per make
				let models = JSON.parse(sessionStorage.getItem('models'));
				let selectedMake = this.make[0].ID;
				let resmodels = models.filter(function (model) {
					if (model.make_id == selectedMake) {
						return true;
					}
				});
				this.model = resmodels;

				this.addVehicleForm.patchValue({
					vehicle_image_1: this.vehicleImageId1,
					vehicle_image_2: this.vehicleImageId2,
					vehicle_image_3: this.vehicleImageId3,
					vehicle_image_4: this.vehicleImageId4,
					vehicle_image_5: this.vehicleImageId5,
					vehicle_image_6: this.vehicleImageId6,
					vehicle_image_7: this.vehicleImageId7,
					vehicle_image_8: this.vehicleImageId8,
					vehicle_image_9: this.vehicleImageId9,
					rearPlateImage: this.rearPlateId,
					windowPermitImage: this.windowPermitId,
					windowPermit2Image: this.windowPermit2Id,
					usdotPermitImage: this.usdotPermitId,
					mcImage: this.mcId,
				});
				this.spinner.hide();
				this.pushValuesTypeOfService(['local'])
			});
		this.Subscriptions()

	}
	closeButton() {
		this.closeTab.emit();
	}
	//Start of autocomplete search and selection
	searchSorting(keyword, a, b) {
		// Sort results by matching name with keyword position in name
		if (a.name.toLowerCase().indexOf(keyword.toLowerCase()) > b.name.toLowerCase().indexOf(keyword.toLowerCase())) {
			return 1;
		} else if (a.name.toLowerCase().indexOf(keyword.toLowerCase()) < b.name.toLowerCase().indexOf(keyword.toLowerCase())) {
			return -1;
		} else {
			if (a.name > b.name)
				return 1;
			else
				return -1;
		}
	}
	handleChangeVehicleType(value) {
		console.log('Selected Value:', value);

		this.isVehicleTypeSelected = value ? true : false
	}

	searchVehicleType(keyword) {
		this.addVehicleForm.patchValue({
			vehicleType: '',
		});
		if (keyword == '') {
			this.filteredVehicleTypes = this.vehicleTypes;
		}
		else {
			this.filteredVehicleTypes = this.vehicleTypes.filter((vehicle_Type: any) => {
				if (vehicle_Type.name.toLowerCase() === keyword.toLowerCase()) {
					this.addVehicleForm.patchValue({
						vehicleType: vehicle_Type.ID,
					});
				}
				return vehicle_Type.name.toLowerCase().includes(keyword.toLowerCase());
			})
				.sort((a: any, b: any) => {
					return this.searchSorting(keyword, a, b)
				});
		}
	}

	selectVehicleType(val, isSelected) {
		if (isSelected)// ignore on deselection of the previous option 
		{
			this.addVehicleForm.patchValue({
				vehicleType: val,
			});
		}
	}

	searchMake(keyword) {
		this.addVehicleForm.patchValue({
			make: '',
		});
		if (keyword == '') {
			this.filteredMake = this.make;
		}
		else {
			this.filteredMake = this.make.filter((mk: any) => {
				if (mk.name.toLowerCase() === keyword.toLowerCase()) {
					this.addVehicleForm.patchValue({
						make: mk.ID,
					});
				}
				return mk.name.toLowerCase().includes(keyword.toLowerCase());
			})
				.sort((a: any, b: any) => {
					return this.searchSorting(keyword, a, b)
				});
		}
	}
	selectMake(val, isSelected) {
		console.log('make select is clicked-->>>', val)
		if (isSelected)// ignore on deselection of the previous option
		{
			this.addVehicleForm.patchValue({
				make: val,
			});
			let modelField: any = document.getElementById('modelField');
			modelField.value = '';
			// setTimeout(() => {
			// 	this.changeMake(val);
			// }, 200)
		}
	}
	Subscriptions() {
		this.addVehicleForm.get('make')?.valueChanges.subscribe((value: string) => {
			console.log('change value is--->>', value)
			// let models = this.allModels
			// this.filteredModel = this.model = models.filter(function (model) {
			// 	if (model.make_id == value) {
			// 		return true;
			// 	}
			// });
			this.changeMake(value);
			let modelField: any = document.getElementById('modelField');
			modelField.value = '';
		})

		// this.addVehicleForm.get('make').valueChanges.subscribe((value: string) => {
		// 	console.log('in function change make-->',value )
		// 	// let models = this.allModels
		// 	// this.filteredModel = this.model = models.filter(function (model) {
		// 	// 	if (model.make_id == value) {
		// 	// 		return true;
		// 	// 	}
		// 	// });
		// })
	}

	searchModel(keyword) {
		this.addVehicleForm.patchValue({
			model: '',
		});
		if (keyword.length) {
			this.filteredModel = this.model.filter((mdl: any) => {
				if (mdl.name.toLowerCase() === keyword.toLowerCase()) {
					this.addVehicleForm.patchValue({
						model: mdl.ID,
					});
				}
				return mdl.name.toLowerCase().includes(keyword.toLowerCase());
			})
				.sort((a: any, b: any) => {
					return this.searchSorting(keyword, a, b)
				});
		}
		else {
			this.filteredModel = this.model;
		}
	}
	selectModel(val, isSelected) {
		if (isSelected)// ignore on deselection of the previous option
		{
			this.addVehicleForm.patchValue({
				model: val,
			});
		}
	}

	searchYear(keyword) {
		this.addVehicleForm.patchValue({
			year: '',
		});
		if (keyword == '') {
			this.filteredYear = this.year;
		}
		else {
			this.filteredYear = this.year.filter((yr: any) => {
				if (yr.name.toLowerCase() === keyword.toLowerCase()) {
					this.addVehicleForm.patchValue({
						year: yr.ID,
					});
				}
				return yr.name.toLowerCase().includes(keyword.toLowerCase());
			})
				.sort((a: any, b: any) => {
					return this.searchSorting(keyword, a, b)
				});
		}
	}
	selectYear(val, isSelected) {
		if (isSelected)// ignore on deselection of the previous option
		{
			this.addVehicleForm.patchValue({
				year: val,
			});
		}
	}

	searchColor(keyword) {
		this.addVehicleForm.patchValue({
			color: '',
		});
		if (keyword == '') {
			this.filteredColor = this.color;
		}
		else {
			this.filteredColor = this.color.filter((cl: any) => {
				if (cl.name.toLowerCase() === keyword.toLowerCase()) {
					this.addVehicleForm.patchValue({
						color: cl.ID,
					});
				}
				return cl.name.toLowerCase().includes(keyword.toLowerCase());
			})
				.sort((a: any, b: any) => {
					return this.searchSorting(keyword, a, b)
				});
		}
	}
	selectColor(val, isSelected) {
		if (isSelected)// ignore on deselection of the previous option
		{
			this.addVehicleForm.patchValue({
				color: val,
			});
		}
	}
	//End of autocomplete search and selection
	// typeOfService(type) {
	// 	this.serviceType = type;
	// }

	get typeOfService(): FormArray {
		return this.addVehicleForm.get('typeOfService') as FormArray;
	}


	pushValuesTypeOfService(value: Array<any>) {
		this.typeOfService.clear()
		this.service = this.addVehicleForm.get('typeOfService').value
		value.forEach((item: string) => {
			!this.service.includes(item) && this.service.push(item)
			this.typeOfService.push(this.formBuilder.control(item))
		})
	}

	onAmenitiesCheckboxChange(val, ischecked) {
		const amenities: FormArray = this.addVehicleForm.get('amenities') as FormArray;
		if (ischecked) {
			amenities.push(new FormControl(val));
		} else {
			const index = amenities.controls.findIndex(x => x.value === val);
			amenities.removeAt(index);
		}
	}
	onSpecialAmenitiesCheckboxChange(e) {
		const specialAmenities: FormArray = this.addVehicleForm.get('specialAmenities') as FormArray;
		if (e.target.checked) {
			specialAmenities.push(new FormControl(e.target.value));
		} else {
			const index = specialAmenities.controls.findIndex(x => x.value === e.target.value);
			specialAmenities.removeAt(index);
		}
	}
	onInteriorsCheckboxChange(e) {
		const vehicleInterior: FormArray = this.addVehicleForm.get('vehicleInterior') as FormArray;
		console.log('------>>>>>>', vehicleInterior)
		if (e.target.checked) {
			vehicleInterior.push(new FormControl(e.target.value));
		} else {
			const index = vehicleInterior.controls.findIndex(x => x.value === e.target.value);
			vehicleInterior.removeAt(index);
		}
	}
	fetchImageBlob(url, key, id) {
		this.stateManagementService.setprogressBar(true);

		this.adminService.fetchImageBlob(url)
			.pipe(
				catchError(err => {
					this.stateManagementService.setprogressBar(false);
					return throwError(err);
				})
			)
			.subscribe(async ({ data }: any) => {
				this.stateManagementService.setprogressBar(false);
				const response = await fetch(data);
				const imageBlob = await response.blob()
				console.log('imageBlob', imageBlob)
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				const img = new Image();
				img.src = URL.createObjectURL(imageBlob);
				console.log('img-->', img)
				img.onload = () => {
					// Rotate the image by 90 degrees (or your desired angle)
					canvas.width = img.width;
					canvas.height = img.height;
					ctx.translate(canvas.width / 2, canvas.height / 2);
					ctx.rotate(Math.PI); // Rotate by 180 degrees
					ctx.drawImage(img, -img.width / 2, -img.height / 2);
					// ctx.drawImage(img, 0, -canvas.width);

					// Convert the canvas to a Blob (JPEG format)
					canvas.toBlob((blob) => {
						console.log(blob);

						this.blobToDataURL(blob, key, id);
						// });
					}, "image/jpeg");
				}
			})
	}
	blobToDataURL(blob: Blob, key, id) {
		var reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = () => {
			let dataUrl = reader.result;
			console.log(dataUrl); //DataURL
			isNaN(parseInt(key)) ? this.vehicleOfficialImagesChange1(dataUrl, key, id) : this.onFileChange1(dataUrl, key, id);
		};
	}
	async onFileChange1(dataUrl, imageNumber, imageId) {
		if (!await this.commonServices.handleFile(event)) {
			return;
		}
		this.stateManagementService.setprogressBar(true);
		this.imageSrc = dataUrl;
		this.adminService.uploadVehicleImage(this.imageSrc)
			.pipe(
				catchError(err => {
					this.stateManagementService.setprogressBar(false);
					return throwError(err);
				})
			)
			.subscribe(result => {
				this.response = result;
				this.addVehicleForm.patchValue({
					["vehicle_image_" + imageNumber]: this.response.data.id,
				});
				this["vehicleImage" + imageNumber] = this.response.data.image;

				this.stateManagementService.setprogressBar(false);
			});
	}
	async vehicleOfficialImagesChange1(url, imageType, imageId) {
		if (!await this.commonServices.handleFile(event)) {
			return;
		}
		this.stateManagementService.setprogressBar(true);
		this.imageSrc = url;
		this.adminService.uploadVehicleImage(this.imageSrc)
			.pipe(
				catchError(err => {
					this.stateManagementService.setprogressBar(false);
					return throwError(err);
				})
			)
			.subscribe(result => {
				this.response = result;

				switch (imageType) {
					case 'rearPlate': {
						this.addVehicleForm.patchValue({
							rearPlateImage: this.response.data.id,
						});
						// this.rearPlateUploaded=true;
						this.rearPlateImage = this.response.data.image;
						// this.deleteImage(imageId,'rearPlate');//delete previous image
						break;
					}
					case 'windowPermit': {
						this.addVehicleForm.patchValue({
							windowPermitImage: this.response.data.id,
						});
						// this.windowPermitUploaded=true;
						this.windowPermitImage = this.response.data.image;
						// this.deleteImage(imageId,'windowPermit');//delete previous image
						break;
					}
					case 'windowPermit2': {
						this.addVehicleForm.patchValue({
							windowPermit2Image: this.response.data.id,
						});
						// this.windowPermit2Uploaded=true;
						this.windowPermit2Image = this.response.data.image;
						// this.deleteImage(imageId,'windowPermit2');//delete previous image
						break;
					}
					case 'usdotPermit': {
						this.addVehicleForm.patchValue({
							usdotPermitImage: this.response.data.id,
						});
						// this.usdotPermitUploaded=true;
						this.usdotPermitImage = this.response.data.image;
						// this.deleteImage(imageId,'usdotPermit');//delete previous image
						break;
					}
					case 'mc': {
						// this.deleteImage(imageId,'mc');//delete previous image
						this.addVehicleForm.patchValue({
							mcImage: this.response.data.id,
						});
						// this.mcUploaded=true;
						this.mcImage = this.response.data.image;
						break;
					}
					default: {
						break;
					}
				}
				this.stateManagementService.setprogressBar(false);
			});
	}
	async vehicleOfficialImagesChange(event, imageType, imageId) {
		if (!await this.commonServices.handleFile(event)) {
			return;
		}
		// this.stateManagementService.setprogressBar(true);
		const reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			const [file] = event.target.files;
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.imageSrc = reader.result as string;
				this.adminService.uploadVehicleImage(this.imageSrc)
					.pipe(
						catchError(err => {
							// this.stateManagementService.setprogressBar(false);
							return throwError(err);
						})
					)
					.subscribe(result => {
						this.response = result;

						switch (imageType) {
							case 'rearPlate': {
								this.addVehicleForm.patchValue({
									rearPlateImage: this.response.data.id,
								});
								// this.rearPlateUploaded=true;
								this.rearPlateImage = this.response.data.image;
								// this.deleteImage(imageId,'rearPlate');//delete previous image
								break;
							}
							case 'windowPermit': {
								this.addVehicleForm.patchValue({
									windowPermitImage: this.response.data.id,
								});
								// this.windowPermitUploaded=true;
								this.windowPermitImage = this.response.data.image;
								// this.deleteImage(imageId,'windowPermit');//delete previous image
								break;
							}
							case 'windowPermit2': {
								this.addVehicleForm.patchValue({
									windowPermit2Image: this.response.data.id,
								});
								// this.windowPermit2Uploaded=true;
								this.windowPermit2Image = this.response.data.image;
								// this.deleteImage(imageId,'windowPermit2');//delete previous image
								break;
							}
							case 'usdotPermit': {
								this.addVehicleForm.patchValue({
									usdotPermitImage: this.response.data.id,
								});
								// this.usdotPermitUploaded=true;
								this.usdotPermitImage = this.response.data.image;
								// this.deleteImage(imageId,'usdotPermit');//delete previous image
								break;
							}
							case 'mc': {
								// this.deleteImage(imageId,'mc');//delete previous image
								this.addVehicleForm.patchValue({
									mcImage: this.response.data.id,
								});
								// this.mcUploaded=true;
								this.mcImage = this.response.data.image;
								break;
							}
							default: {
								break;
							}
						}
						// this.stateManagementService.setprogressBar(false);
					});
			};
		}
	}

	async onFileChange(event, imageId, imageNumber) {
		if (!await this.commonServices.handleFile(event)) {
			return;
		}
		this.stateManagementService.setprogressBar(true);
		const reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			const [file] = event.target.files;
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.imageSrc = reader.result as string;
				this.adminService.uploadVehicleImage(this.imageSrc)
					.pipe(
						catchError(err => {
							this.stateManagementService.setprogressBar(false);
							return throwError(err);
						})
					)
					.subscribe(result => {
						this.response = result;
						this.addVehicleForm.patchValue({
							["vehicle_image_" + imageNumber]: this.response.data.id,
						});
						this["vehicleImage" + imageNumber] = this.response.data.image;

						this.stateManagementService.setprogressBar(false);
					});
			};
		}
	}



	showImageInModal(imageUrl) {
		this.modalImage = imageUrl;
		$("#imageModal").addClass("showImage");
		$("#imageModal").removeClass("d-none");
	}

	deleteImage(id, imageType, imageNumber = null) {
		switch (imageType) {
			case 'rearPlate': {
				this.addVehicleForm.patchValue({
					rearPlateImage: '',
				});
				this.rearPlateImage = '';
				break;
			}
			case 'windowPermit': {
				this.addVehicleForm.patchValue({
					windowPermitImage: '',
				});
				this.windowPermitImage = '';
				break;
			}
			case 'windowPermit2': {
				this.addVehicleForm.patchValue({
					windowPermit2Image: '',
				});
				this.windowPermit2Image = '';
				break;
			}
			case 'usdotPermit': {
				this.addVehicleForm.patchValue({
					usdotPermitImage: '',
				});
				this.usdotPermitImage = '';
				break;
			}
			case 'mc': {
				this.addVehicleForm.patchValue({
					mcImage: '',
				});
				this.mcImage = '';
				break;
			}
			case 'vehicleImage': {
				this.addVehicleForm.patchValue({
					["vehicle_image_" + imageNumber]: '',
				});
				this["vehicleImage" + imageNumber] = '';
				break;
			}
			default: {
				break;
			}
		}
	}


	get f() {
		return this.addVehicleForm.controls;
	}

	submitForm() {
		this.addVehicleForm.patchValue({
			acc_id: this.affiliateId
		});

		this.pushValuesTypeOfService(this.service)

		this.submittedForm = true;
		// stop here if form is invalid
		if (this.addVehicleForm.invalid) {
			return;
		}
		if (this.addVehicleForm.get('vehicle_image_1').value == this.vehicleImageId1) {
			this.errorModal.openDialog({
				errors: {
					error: 'Please change Vehicle Image!'
				}
			})
			return;
		}
		this.spinner.show(); // show spinner
		this.disableSubmitButton = true; //disable submit button

		this.adminService.adminAffiliateSubmitVehicle(this.addVehicleForm.value)
			.pipe(
				catchError(err => {
					this.spinner.hide(); // hide spinner
					this.disableSubmitButton = false; //enable submit button
					return throwError(err);
				})
			)
			.subscribe(result => {
				this.response = result;
				this.spinner.hide(); // hide spinner
				this.disableSubmitButton = true; //enable submit button

				this.stateManagementService.addNumberOfVehicles(this.addVehicleForm.value.numberOfVehicles);

				this.router.navigate(['admin/add-vehicle-rates-subscriber'], { queryParams: { vehicleId: this.response.data.id } });
			});
	}
	backButton() {
		this.router.navigate(['admin/vehicle-details']);
	}

	resetForm() {
		this.submittedForm = true;
		this.addVehicleForm.reset();
		this.vehicleImageArray = [];

		let vehicleTypeField: any = document.getElementById('vehicleTypeField');
		vehicleTypeField.value = "";
		let colorField: any = document.getElementById('colorField');
		colorField.value = "";
		let makeField: any = document.getElementById('makeField');
		makeField.value = "";
		let modelField: any = document.getElementById('modelField');
		modelField.value = "";
		let yearField: any = document.getElementById('yearField');
		yearField.value = "";
		this.vehicleImage1 = this.oldvehicleImage[0];
		this.vehicleImage2 = this.oldvehicleImage[1];
		this.vehicleImage3 = this.oldvehicleImage[2];
		this.vehicleImage4 = this.oldvehicleImage[3];
		this.vehicleImage5 = this.oldvehicleImage[4];
		this.vehicleImage6 = this.oldvehicleImage[5];
		this.vehicleImage7 = this.oldvehicleImage[6];
		this.vehicleImage8 = this.oldvehicleImage[7];
		this.vehicleImage9 = this.oldvehicleImage[8];
		this.rearPlateImage = this.oldvehicleImage[9];
		this.windowPermitImage = this.oldvehicleImage[10];
		this.windowPermit2Image = this.oldvehicleImage[11];
		this.usdotPermitImage = this.oldvehicleImage[12];
		this.mcImage = this.oldvehicleImage[13];
	}

	changeMake(selectedMake) {
		console.log('selectedMake-->>>>', selectedMake)
		if (!selectedMake) {
			this.addVehicleForm.patchValue({
				model: ''
			})
			this.filteredModel = []
			return false
		}

		let models = JSON.parse(sessionStorage.getItem('models'));
		this.filteredModel = models.filter(function (model) {
			if (model.make_id == selectedMake) {
				return true;
			}
		});
		this.addVehicleForm.patchValue({
			model: this.filteredModel[0]?.ID
		})
	}
	service: Array<any> = []
	onServiceChange(value: string) {
		console.log(value)
		const index = this.service.indexOf(value);
		if (index === -1) {
			// If the service type is not selected, add it to the array
			this.service.push(value);
		} else {
			// If the service type is already selected, remove it from the array
			this.service.splice(index, 1);
		}
		// if (this.service.includes(value)) {
		// 	// a never reaching code line
		// 	this.service = this.service.filter(val => val != value)
		// } else {
		// 	this.service = []
		// 	this.service.push(value)
		// }

		// // as per new update from client: he wants to make the whole thing work as a radio button
		// return
		// if (!is_service_valid(value, this.service)) {
		// 	this.errorModal.openDialog({
		// 		errors: {
		// 			error: 'Cannot choose Local and Over The Road service at the same time'
		// 		}
		// 	})
		// 	this.service = this.service.filter(val => val != value)
		// 	return
		// }
		// console.log('Inital Array: ', this.service)

		// /**
		//  * The Array Validation Check function
		//  * - make sure the array doesn't containe 'local' and 'over_the_road' values at a time.
		//  * 
		//  * @param value: String [Required] value to check
		//  */
		// function is_service_valid(value: string, service: Array<any>) {
		// 	if (value == 'local' && service.includes('over_the_road')) {
		// 		return false
		// 	} else if (value == 'over_the_road' && service.includes('local')) {
		// 		return false
		// 	}
		// 	else {
		// 		return true
		// 	}
		// }
	}


}

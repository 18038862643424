<ngx-spinner></ngx-spinner>
<div class="partner_registration_wrapper">
    <div class="partner_reg_div">
    <form [formGroup]="cardDetails" appInvalidControlScroll>
        <section class="user_information">
            <h2 class="planDetails_heading adminbox_shadow">{{planName}}: $ {{planPrice}}</h2>
            <h2 class="headinginfo adminbox_shadow"> Payment Detail</h2>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Card No <span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="Card No" formControlName="number" id="card-number"
                            (focus)="$event.target.select()">
                    </mat-form-field>
                    <div *ngIf="submittedForm && f.number.errors || f.number.touched && f.number.errors"
                        class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.number.errors.required">This field is required</div>
                    </div>
                    <div *ngIf="f.number.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.number.errors?.invalid_Pattern;else plusError">Do not add
                            Dashes
                            with numbers</div>
                        <ng-template #plusError>
                            <div *ngIf="f.number.errors?.invalidPattern;else allErrors">Do not
                                enter +
                                sign</div>
                            <ng-template #allErrors>
                                <div *ngIf="f.number.errors?.pattern;else numberLength">Enter
                                    Numbers Only
                                </div>
                                <ng-template #numberLength>
                                    <div *ngIf="f.number.errors.minlength">Minimum length should
                                        be 16</div>
                                    <div *ngIf="f.number.errors.maxlength">Maximum length should
                                        be 20</div>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Expiry Month <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="exp_month">
                            <mat-option value="">Choose Expiry Month</mat-option>
                            <mat-option value='01'>01</mat-option>
                            <mat-option value='02'>02</mat-option>
                            <mat-option value='03'>03</mat-option>
                            <mat-option value='04'>04</mat-option>
                            <mat-option value='05'>05</mat-option>
                            <mat-option value='06'>06</mat-option>
                            <mat-option value='07'>07</mat-option>
                            <mat-option value='08'>08</mat-option>
                            <mat-option value='09'>09</mat-option>
                            <mat-option value='10'>10</mat-option>
                            <mat-option value='11'>11</mat-option>
                            <mat-option value='12'>12</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submittedForm && f.exp_month.errors || f.exp_month.touched && f.exp_month.errors"
                        class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.exp_month.errors.required">This field is required</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Expiry Year <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="exp_year">
                            <mat-option value="">Choose Expiry Year</mat-option>
                            <mat-option *ngFor="let yearOption of yearOptions" value="{{yearOption}}">{{yearOption}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submittedForm && f.exp_year.errors || f.exp_year.touched && f.exp_year.errors"
                        class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.exp_year.errors.required">This field is required</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>CVV (3-4 digit code) <span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="CVV (3-4 digit code)" formControlName="cvc"
                            (focus)="$event.target.select()">
                    </mat-form-field>
                    <div *ngIf="submittedForm && f.cvc.errors || f.cvc.touched && f.cvc.errors"
                        class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.cvc.errors.required">This field is required</div>
                    </div>
                    <div *ngIf="f.cvc.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.cvc.errors?.invalid_Pattern;else plusError">Do not add
                            Dashes
                            with numbers</div>
                        <ng-template #plusError>
                            <div *ngIf="f.cvc.errors?.invalidPattern;else allErrors">Do not
                                enter +
                                sign</div>
                            <ng-template #allErrors>
                                <div *ngIf="f.cvc.errors?.pattern;else numberLength">Enter
                                    Numbers Only
                                </div>
                                <ng-template #numberLength>
                                    <div *ngIf="f.cvc.errors.minlength">Minimum length should be
                                        3</div>
                                    <div *ngIf="f.cvc.errors.maxlength">Maximum length should be
                                        5</div>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="col-md-12 d-flex mt-4">
                <button class="custom-filled-button" (click)="submitForm()">
                    Pay now
                </button>
            </div>
        </section>
    </form>
    </div>
</div>
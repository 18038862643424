<!-- <ngx-spinner></ngx-spinner> -->
<!-- <main> -->
<!-- Main page content-->
<section class="driverbooking_top user_information">
	<h2 class="headinginfo">Add Driver <span>(s)</span></h2>
	<div class="containeredd">
		<!-- <div class="row mt-3">
			<div class="col-md-12 mb-2">
				<label class="alert alert-danger" *ngIf="affiliateType=='fleet_operator'; else otherOperator">Note :
					Fleet/Coach Operators can enter unlimited drivers.</label>
				<ng-template #otherOperator>
					<label class="alert alert-danger">Note: Black Car / Limo Op / Taxi / Gig Op Can Only Enter 1
						Driver.</label>
				</ng-template>
			</div>
		</div> -->
		<div class="row" *ngIf="(currentUser?.created_by_role == 'subscriber' && currentUser?.roleName == 'admin')">
			<div class="col-md-12 d-flex" style="gap: 10px;">
				<button class="custom-btn add-button btn-shadow" (click)="addDriverClick()" tabindex="1">
					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-plus-circle"
						viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						<path
							d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
					</svg> Add Driver
				</button>
				<button class="custom-btn add-button btn-shadow" tabindex="1" data-target="#inviteDriverModal"
				data-toggle="modal">
					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-plus-circle"
						viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						<path
							d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
					</svg> Invite Driver
				</button>
			</div>
		</div>
	</div>
	<div class="containeredd driverside_table">
		<div class="row">
			<div class="col-md-12 custom_table table-responsive">
				<table class="table table-striped dataTable dt-responsive" id="dataTable" role="grid"
					aria-describedby="dataTable_info" style="width: 100%;" width="100%" cellspacing="0">
					<thead>
						<tr role="row">
							<th class="tableWidth">Actions</th>
							<th>Driver Image</th>
							<th>Name</th>
							<th>Cell</th>
							<th>Email</th>
							<th>Delete</th>
							<!-- <th [hidden]="affiliateType!='fleet_operator'">Account Status</th> -->
						</tr>
					</thead>
					<tbody>
						<ng-container *ngIf="driverList">
							<tr role="row" class="odd" *ngFor="let driver of driverList">
								<td class="tableWidth">
									<a class="user-profileded btn btn-icon btn-transparent-dark btn-shadow"
										id="navbarDropdownUserImage" (click)="clickEditDriver(driver.id)" role="button"
										tabindex="1"> <span>Edit Driver</span>
									</a>
								</td>
								<td><img class="rounded-circle" src="{{driver.DriverImage.image}}"></td>
								<td class="vehicleFontDesign">{{driver.FirstName+' '+(driver?.MiddleName||'')+'
									'+driver.LastName}}</td>
								<td class="pointer vehicleFontDesign"><a
										href="tel:{{driver.CellIsd+' '+driver.CellNumber}}"
										tabindex="-1">{{driver.CellIsd+' '+driver.CellNumber}}</a></td>
								<td class="pointer vehicleFontDesign"><a href="mailto:{{driver.Email}}"
										tabindex="-1">{{driver.Email}}</a>
								</td>
								<td>
									<i class="fa fa-trash pointer" style="font-size: x-large; color:#d64646"
										(click)="enableDisableClicked(driver.id)" data-toggle="modal"
										data-target="#deleteConfirmationModal"></i>
								</td>
								<td [hidden]="affiliateType!='fleet_operator'">
									<div class="row">
										<div class="col-md-12">
											<a class="status-btn btn-ctn btn-shadow mr-1" id="Continue_{{driver.id}}"
												[ngClass]="driver.status ? 'checkedContinueLabel' : ''"
												data-toggle="modal" data-target="#suspendModal"
												(click)="driverAccountStatus(driver.id, 'continue')" role="button"
												tabindex="1"> <span>Active</span>
											</a>
											<a class="status-btn btn-suspend btn-shadow" id="Suspend_{{driver.id}}"
												[ngClass]="!driver.status ? 'checkedSuspendLabel' : ''"
												data-toggle="modal" data-target="#suspendModal"
												(click)="driverAccountStatus(driver.id, 'suspend')" role="button"
												tabindex="1"> <span>Suspend</span>
											</a>
											<!-- <label id="Continue_{{driver.id}}" class="toggle toggle-left continueLabel box-shadow"
												[ngClass]="driver.status ? 'checkedContinueLabel' : ''"
												(click)="driverAccountStatus(driver.id, 'continue')" data-toggle="modal"
												data-target="#suspendModal">Continue</label>

											<label id="Suspend_{{driver.id}}" class="toggle toggle-right suspendLabel box-shadow"
												[ngClass]="!driver.status ? 'checkedSuspendLabel' : ''"
												data-toggle="modal" data-target="#suspendModal"
												(click)="driverAccountStatus(driver.id, 'suspend')">Suspend</label> -->
										</div>
									</div>
								</td>
							</tr>
							<tr *ngIf="!driverList?.length">
								<td colspan="7">No Driver Found!</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</section>
<!-- </main> -->
<!-- Delete confirmation modal -->
<app-delete-confirmation (deleteRecord)="delete()" [alertMessage]="alertMessage"></app-delete-confirmation>

<!-- Suspend/Continue Confirmation modal -->
<div class="modal fade" id="suspendModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
	aria-hidden="true" data-backdrop="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="text-center">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="70">
					<g data-name="Layer 51" fill="#ea4444" class="color000 svgShape">
						<path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"
							fill="#ea4444" class="color000 svgShape"></path>
						<path
							d="M22.71,9.29a1,1,0,0,0-1.42,0L16,14.59l-5.29-5.3a1,1,0,0,0-1.42,1.42L14.59,16l-5.3,5.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,17.41l5.29,5.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L17.41,16l5.3-5.29A1,1,0,0,0,22.71,9.29Z"
							fill="#ea4444" class="color000 svgShape"></path>
					</g>
				</svg>
				<h5 class="modal-title my-3" id="exampleModalLabel">Are you sure you want to {{storeStatus}} this
					Driver?</h5>
			</div>
			<div class="text-center">
				<button type="button" class="custom-btn yes-btn mr-2" *ngIf="storeStatus == 'continue'"
					(click)="accountStatus('enable')">Yes</button>
				<button type="button" class="custom-btn yes-btn mr-2" *ngIf="storeStatus == 'suspend'"
					(click)="accountStatus('suspend')">Yes</button>
				<button type="button" class="custom-btn no-btn" data-dismiss="modal" aria-label="Close">No</button>
			</div>
		</div>
	</div>
</div>


<!-- invite agent modal -->
<div class="modal fade" tabindex="-1" id="inviteDriverModal" aria-labelledby="inviteDriverModal" aria-hidden="true">
	<div class="modal-dialog invite-agent-modal-dialog modal-dialog-centered">
		<div class="modal-content" style="padding: 0px;">
			<div class="p-2 justify-content-end">

				<button type="button" class="close x-circle-btn" data-dismiss="modal" aria-label="Close"
					(click)="closeInviteModal()">
					<span aria-hidden="true" class="bi bi-x-circle"></span>
				</button>
			</div>

			<div class="invite-agent-modal-header d-flex flex-column align-items-center justify-content-center">
				<h4 style="font-size:20px;">Invite driver under your company name!</h4>
			</div>

			<form [formGroup]="inviteDriverForm">
				<div class="form-group">
					<div class="col-md-12">
						<mat-form-field appearance="outline">
							<mat-label>Enter driver's email <span class="asterisk">*</span></mat-label>
							<input matInput type="text" formControlName="email_address" style="font-weight: 500;">
						</mat-form-field>
						<div *ngIf="(submittedForm && f.email_address.errors) ||(f.email_address.touched && f.email_address.errors)"
							class="text-danger margin-bottom-thin my-mat-hint">
							<div *ngIf="f.email_address.errors.required">
								This field is required
							</div>
						</div>
						<div *ngIf="f.email_address.errors" class="text-danger margin-bottom-thin my-mat-hint">
							<div *ngIf="f.email_address.errors.pattern">
								Email format should be valid
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer justify-content-center">
					<button type="submit" class="custom-filled-button" (click)="sendInvite()"
						style="font-size: 1.10rem; font-weight:700; text-transform: uppercase;">
						Submit
					</button>
				</div>
			</form>

		</div>
	</div>
</div>
<section class="mainveh_wrapper">
   <div class="container clientebooking_info">
      <h2>Reservation Cancellation e-Mail</h2>
      <div class="row ">
         <div class="col-md-7 col-lg-6">
            <span><strong>Subject:</strong>Reservation Cancellation 280229907</span>
            <h3>Booking #: 4585605464</h3>
         </div>
         <div class="col-md-5 col-lg-6">
            <img src="assets/admin/images/logo-login.png">
         </div>
         <div class="col-md-12">
            <h6 class="reservation_cancel">Reservation Cancelled</h6>
         </div>
      </div>
   </div>
   <div class="container">
      <div class=" emailconfirm_servtype">
         <h2>Reservation Details</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Passenger Name:</p>
               <label class="col-md-8">James Simpkins </label>
            </li>
            <li>
               <p class="col-md-4">Pickup Date:</p>
               <label class="col-md-8">03/30/2020 | 30Mar2020 </label>
            </li>
            <li>
               <p class="col-md-4">Pickup Time:</p>
               <label class="col-md-8">10:00am | 1000 </label>
            </li>
            <li>
               <p class="col-md-4">Transffer Type:</p>
               <label class="col-md-8">Airport </label>
            </li>
            <li>
               <p class="col-md-4">Vehicle Type:</p>
               <label class="col-md-8">Wagon </label>
            </li>
            <li>
               <p class="col-md-4">Pickup Location:</p>
               <label class="col-md-8">Airport - Newark International - EWR </label>
            </li>
            <li>
               <p class="col-md-4">Drop Off Location:</p>
               <label class="col-md-8">City - 28 Dogwood Drive, Chester, NJ </label>
            </li>
         </ul>
      </div>
   </div>
</section>
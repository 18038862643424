<!-- <div class="fixed-chat-logo" (click)="chevronFunctionality()" ><img  src="../../../../assets/images/chat_logo.png" alt="logo-chat" height="80px"></div> -->
<!-- style="width: inherit;" -->
<!-- *ngIf="chevron" -->
<img src="../../../../assets/images/chat_logo.svg" class="chat-logo" (click)="chevronFunctionality()" alt="logo-chat"
	data-toggle="modal" data-target="#chat-modal">



<div class="modal" id="chat-modal" data-keyboard="false" tabindex="-1" aria-labelledby="chat-modal-label"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content" id="fixed-form-container" style="background-color:#f5f4fe;">
			<section>
				<div class="button expanded" type="button" data-dismiss="modal">
					<span>Chat Now</span>
					<p class="m-0" [ngClass]="'bi bi-chevron-down'"></p>
				</div>
				<div class="body" [ngStyle]="{'display':'block'}">
					<div>
						<h3><a _ngcontent-htv-c76="" href="tel:+17082056607">+1 708 205 6607</a></h3>
					</div>
					<br>
					<div class="container">

						<div class="floatIconsf">
							<div class="row">

								<div class="col">
									<li>
										<!-- href=" https://www.facebook.com/1800LIMO" target="_blank" -->
										<a _ngcontent-htv-c76="" href="tel:+17082056607">
											<!-- <!DOCTYPE svg
												PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'>
											<svg enable-background="new 0 0 128 128" height="148px" id="Layer_1"
												version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink">
												<g>
													<circle cx="64" cy="64" fill="#3C579E" r="64" />
												</g>
												<path
													d="M56.256,104V67.854H43.355V54.965h12.903V43.069c0-12,7.084-19.069,17.788-19.069  c5.129,0,9.313,0.548,10.598,0.719v13.478l-8.617-0.004c-5.819,0-6.91,2.887-6.91,6.945v9.828h14.916l-2.106,12.888H69.119V104  H56.256z"
													fill="#FFFFFF" />
											</svg> -->
											<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 122.88 122.88" height="38" width="48"
												style="margin-right: 10px;">
												<defs>
													<style>
														.phoneCls-1 {
															fill: #33a867;
															fill-rule: evenodd;
														}
													</style>
												</defs>
												<path class="phoneCls-1"
													d="M61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM48.1,56.19A49.35,49.35,0,0,0,55.39,66.4a41.25,41.25,0,0,0,11.55,8.51,1.14,1.14,0,0,0,1,0,4.48,4.48,0,0,0,1.39-1A16.91,16.91,0,0,0,70.6,72.4c1.85-2.44,4.15-5.47,7.39-4l.2.11L89,74.77l.11.07a4.84,4.84,0,0,1,2,4.2,11.43,11.43,0,0,1-1.58,5.37,11.06,11.06,0,0,1-5.2,4.6A23.63,23.63,0,0,1,78,90.75a21.69,21.69,0,0,1-9.8-.82,43.06,43.06,0,0,1-9.85-4.76L58.05,85c-1.6-1-3.33-2.07-5-3.33a62.69,62.69,0,0,1-16.65-18.9c-3.46-6.26-5.34-13-4.32-19.44a14,14,0,0,1,4.72-8.85A12.68,12.68,0,0,1,46.18,32a1.35,1.35,0,0,1,1.09.7L54.21,44.4a3.68,3.68,0,0,1,.59,3.92,7.71,7.71,0,0,1-2.66,3c-.37.32-.82.64-1.29,1-1.55,1.12-3.32,2.42-2.71,4l0-.05Z" />
											</svg>
											<h6>Contact us </h6>
										</a>

									</li>
								</div>

								<div class="col mailBoxStyle">
									<li>
										<a href="mailto:info@1800limo.com" target="_blank"><svg
												xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px"
												height="48px">
												<linearGradient id="NvW_RwTAona1rlOp72mkqa" x1="24" x2="24" y1="42"
													y2="6.207" gradientUnits="userSpaceOnUse">
													<stop offset="0" stop-color="#1ac8fc" />
													<stop offset=".258" stop-color="#1bb6fa" />
													<stop offset=".775" stop-color="#1c86f5" />
													<stop offset="1" stop-color="#1d70f2" />
												</linearGradient>
												<path fill="url(#NvW_RwTAona1rlOp72mkqa)"
													d="M34,42H14c-4.411,0-8-3.589-8-8V14c0-4.411,3.589-8,8-8h20c4.411,0,8,3.589,8,8v20 C42,38.411,38.411,42,34,42z" />
												<path
													d="M34,16c0.178,0,0.347,0.031,0.512,0.074l-7.824,7.825C25.946,24.64,24.973,25.01,24,25.01 s-1.946-0.37-2.688-1.111l-7.824-7.825C13.653,16.031,13.822,16,14,16H34 M35.926,17.488C35.969,17.653,36,17.822,36,18v12 c0,0.178-0.031,0.347-0.074,0.512L29.414,24L35.926,17.488 M12.074,17.488L18.586,24l-6.511,6.511C12.031,30.347,12,30.178,12,30 V18C12,17.822,12.031,17.653,12.074,17.488 M27.99,25.404l6.522,6.522C34.347,31.969,34.178,32,34,32H14 c-0.178,0-0.347-0.031-0.512-0.074l6.522-6.522c1.117,1.063,2.55,1.605,3.99,1.605S26.873,26.467,27.99,25.404 M34,15H14 c-0.244,0-0.495,0.035-0.768,0.108c-0.346,0.092-0.616,0.361-0.709,0.707c-0.092,0.341,0.003,0.704,0.248,0.956 c-0.189-0.183-0.44-0.283-0.697-0.283c-0.087,0-0.174,0.011-0.26,0.034c-0.345,0.093-0.615,0.363-0.707,0.709 C11.035,17.505,11,17.756,11,18v12c0,0.244,0.035,0.495,0.108,0.768c0.092,0.346,0.361,0.616,0.707,0.709 c0.086,0.023,0.173,0.034,0.26,0.034c0.257,0,0.508-0.099,0.696-0.282c-0.245,0.253-0.34,0.616-0.248,0.956 c0.093,0.345,0.363,0.615,0.709,0.707C13.505,32.965,13.756,33,14,33h20c0.244,0,0.495-0.035,0.768-0.108 c0.346-0.092,0.616-0.361,0.709-0.707c0.092-0.341-0.003-0.704-0.248-0.956c0.189,0.183,0.44,0.282,0.696,0.282 c0.087,0,0.174-0.011,0.26-0.034c0.345-0.093,0.615-0.363,0.707-0.709C36.965,30.495,37,30.244,37,30V18 c0-0.244-0.035-0.495-0.108-0.768c-0.092-0.346-0.361-0.616-0.707-0.709c-0.086-0.023-0.173-0.034-0.26-0.034 c-0.257,0-0.508,0.099-0.697,0.282c0.245-0.253,0.34-0.616,0.248-0.956c-0.093-0.345-0.363-0.615-0.709-0.707 C34.495,15.035,34.244,15,34,15L34,15z"
													opacity=".05" />
												<path
													d="M34,15.5H14c-0.203,0-0.406,0.029-0.64,0.091c-0.173,0.046-0.308,0.181-0.354,0.353 c-0.046,0.173,0.003,0.357,0.129,0.483l7.824,7.825C21.77,25.063,22.85,25.51,24,25.51s2.23-0.447,3.041-1.257l7.824-7.825 c0.126-0.126,0.176-0.311,0.129-0.483c-0.046-0.173-0.182-0.307-0.354-0.353C34.406,15.529,34.203,15.5,34,15.5L34,15.5z M35.926,16.988c-0.131,0-0.259,0.051-0.354,0.146l-6.511,6.512c-0.195,0.195-0.195,0.512,0,0.707l6.511,6.511 c0.095,0.095,0.223,0.146,0.354,0.146c0.043,0,0.087-0.006,0.13-0.017c0.173-0.046,0.307-0.182,0.353-0.354 C36.471,30.406,36.5,30.203,36.5,30V18c0-0.203-0.029-0.406-0.091-0.64c-0.046-0.173-0.181-0.308-0.353-0.354 C36.013,16.994,35.969,16.988,35.926,16.988L35.926,16.988z M12.074,16.988c-0.043,0-0.087,0.006-0.13,0.017 c-0.173,0.046-0.307,0.182-0.353,0.354C11.529,17.594,11.5,17.797,11.5,18v12c0,0.203,0.029,0.406,0.091,0.64 c0.046,0.173,0.181,0.308,0.353,0.354c0.043,0.012,0.087,0.017,0.13,0.017c0.131,0,0.259-0.051,0.354-0.146l6.511-6.511 c0.195-0.195,0.195-0.512,0-0.707l-6.511-6.512C12.333,17.04,12.205,16.988,12.074,16.988L12.074,16.988z M27.99,24.904 c-0.124,0-0.248,0.046-0.345,0.138c-0.995,0.946-2.289,1.467-3.645,1.467s-2.651-0.521-3.645-1.467 c-0.097-0.092-0.221-0.138-0.345-0.138c-0.128,0-0.256,0.049-0.354,0.146l-6.522,6.522c-0.126,0.126-0.176,0.311-0.129,0.483 s0.182,0.307,0.354,0.353C13.594,32.471,13.797,32.5,14,32.5h20c0.203,0,0.406-0.029,0.64-0.091 c0.173-0.046,0.308-0.181,0.354-0.353s-0.003-0.357-0.129-0.483l-6.522-6.522C28.246,24.953,28.118,24.904,27.99,24.904 L27.99,24.904z"
													opacity=".07" />
												<path fill="#f9f9f9"
													d="M35.926,17.488L29.414,24l6.511,6.511C35.969,30.347,36,30.178,36,30V18 C36,17.822,35.969,17.653,35.926,17.488z M26.688,23.899l7.824-7.825C34.347,16.031,34.178,16,34,16H14 c-0.178,0-0.347,0.031-0.512,0.074l7.824,7.825C22.795,25.38,25.205,25.38,26.688,23.899z M12.074,17.488 C12.031,17.653,12,17.822,12,18v12c0,0.178,0.031,0.347,0.074,0.512L18.586,24L12.074,17.488z M24,27.009 c-1.44,0-2.873-0.542-3.99-1.605l-6.522,6.522C13.653,31.969,13.822,32,14,32h20c0.178,0,0.347-0.031,0.512-0.074l-6.522-6.522 C26.873,26.467,25.44,27.009,24,27.009z" />
											</svg>
											<h6>Mail</h6>
										</a>
									</li>
								</div>
								<div class="col">
									<li>
										<a href="https://wa.me/17082056607" target="_blank"><svg id="Layer_1"
												data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 122.88 122.88">
												<defs>
													<style>
														.clsWhatsapp-1 {
															fill: #25d366;
														}

														.clsWhatsapp-1,
														.cls-2 {
															fill-rule: evenodd;
														}

														.cls-2 {
															fill: #fff;
														}
													</style>
												</defs>
												<title>whatsapp-round-color</title>
												<path class="clsWhatsapp-1"
													d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z" />
												<path class="cls-2"
													d="M77,67.57c-.76-.39-4.52-2.23-5.22-2.49s-1.2-.38-1.72.39-2,2.48-2.43,3-.89.58-1.65.19a20.93,20.93,0,0,1-6.14-3.8,23.3,23.3,0,0,1-4.26-5.29A1.1,1.1,0,0,1,55.92,58c.35-.34.76-.89,1.15-1.34a5.34,5.34,0,0,0,.76-1.28,1.43,1.43,0,0,0-.06-1.34c-.2-.38-1.73-4.14-2.35-5.68s-1.26-1.28-1.73-1.31-.95,0-1.46,0a2.82,2.82,0,0,0-2,1,8.58,8.58,0,0,0-2.68,6.38c0,3.76,2.74,7.39,3.12,7.91S56,70.52,63.7,73.82a45.51,45.51,0,0,0,4.35,1.62,10.43,10.43,0,0,0,4.81.3c1.47-.21,4.52-1.84,5.16-3.63a6.37,6.37,0,0,0,.45-3.63c-.18-.34-.69-.53-1.46-.92Zm7.7-28a30.59,30.59,0,0,0-48.13,36.9L32.23,92.3l16.22-4.25a30.61,30.61,0,0,0,14.6,3.72h0A30.59,30.59,0,0,0,84.69,39.55ZM63.07,86.61a25.46,25.46,0,0,1-13-3.54l-.93-.56L39.57,85l2.57-9.38-.6-1A25.41,25.41,0,1,1,63.06,86.61Z" />
											</svg>
											<h6>Live Chat</h6>
										</a>
									</li>
								</div>

							</div>

						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.scss']
})
export class InvestorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

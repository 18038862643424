<div class="innerpage_wrapper">
	<div class="innerpage_topbanner">
		<h2>
			GET IN TOUCH
			<hr>
		</h2>
	</div>
	<section class="contactus_wrapper">
		<div class="container login-container">
			<div class="row">
				<div class="col-md-6 ads">
					<ul class=" details">
						<li><i class="fa fa-envelope" aria-hidden="true"></i> <strong>Email</strong> <br>
							<label>info@1800limo.com </label>
						</li>
						<li><i class="fas fa-phone-alt" aria-hidden="true"></i> <strong>Contact</strong> <br>
							<label>+1-800-5466.266</label>
						</li>
						<li>
							<strong>Chat with Admin</strong><br><br>
							<a href=" https://www.facebook.com/1800LIMO" target="_blank">
								<!DOCTYPE svg
									PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'>
								<svg enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1"
									viewBox="0 0 128 128" width="128px" xml:space="preserve"
									xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<g>
										<circle cx="64" cy="64" fill="#3C579E" r="64" />
									</g>
									<path
										d="M56.256,104V67.854H43.355V54.965h12.903V43.069c0-12,7.084-19.069,17.788-19.069  c5.129,0,9.313,0.548,10.598,0.719v13.478l-8.617-0.004c-5.819,0-6.91,2.887-6.91,6.945v9.828h14.916l-2.106,12.888H69.119V104  H56.256z"
										fill="#FFFFFF" />
								</svg>
							</a>

							<a href="https://api.whatsapp.com/send?phone=+918572001296" target="_blank"><svg
									id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 122.88 122.88">
									<defs>
										<style>
											.clsWhatsapp-1 {
												fill: #25d366;
											}

											.clsWhatsapp-1,
											.cls-2 {
												fill-rule: evenodd;
											}

											.cls-2 {
												fill: #fff;
											}
										</style>
									</defs>
									<title>whatsapp-round-color</title>
									<path class="clsWhatsapp-1"
										d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z" />
									<path class="cls-2"
										d="M77,67.57c-.76-.39-4.52-2.23-5.22-2.49s-1.2-.38-1.72.39-2,2.48-2.43,3-.89.58-1.65.19a20.93,20.93,0,0,1-6.14-3.8,23.3,23.3,0,0,1-4.26-5.29A1.1,1.1,0,0,1,55.92,58c.35-.34.76-.89,1.15-1.34a5.34,5.34,0,0,0,.76-1.28,1.43,1.43,0,0,0-.06-1.34c-.2-.38-1.73-4.14-2.35-5.68s-1.26-1.28-1.73-1.31-.95,0-1.46,0a2.82,2.82,0,0,0-2,1,8.58,8.58,0,0,0-2.68,6.38c0,3.76,2.74,7.39,3.12,7.91S56,70.52,63.7,73.82a45.51,45.51,0,0,0,4.35,1.62,10.43,10.43,0,0,0,4.81.3c1.47-.21,4.52-1.84,5.16-3.63a6.37,6.37,0,0,0,.45-3.63c-.18-.34-.69-.53-1.46-.92Zm7.7-28a30.59,30.59,0,0,0-48.13,36.9L32.23,92.3l16.22-4.25a30.61,30.61,0,0,0,14.6,3.72h0A30.59,30.59,0,0,0,84.69,39.55ZM63.07,86.61a25.46,25.46,0,0,1-13-3.54l-.93-.56L39.57,85l2.57-9.38-.6-1A25.41,25.41,0,1,1,63.06,86.61Z" />
								</svg></a>
						</li>
					</ul>
				</div>
				<div class="col-md-6 login-form">
					<h3>Send us Message</h3>
					<form [formGroup]="getInTouchForm" (ngSubmit)="onSubmitGetInTouch()">
						<div class="form-group">
							<input type="text" id="contactName" class="form-control" placeholder="Enter Your Name"
								formControlName="name">
						</div>
						<div *ngIf="submitted && fGetInTouch.name.errors" class="text-danger">
							<div *ngIf="fGetInTouch.name.errors.required">Name is required</div>
						</div>
						<div class="form-group">
							<input type="text" id="contactEmail" class="form-control" placeholder="Enter Your Email"
								formControlName="email">
						</div>
						<div *ngIf="submitted && fGetInTouch.email.errors" class="text-danger">
							<div *ngIf="fGetInTouch.email.errors.required">Email is required</div>
							<div *ngIf="fGetInTouch.email.errors.pattern">Email must be a valid email address</div>
						</div>
						<div class="form-group">
							<input type="text" id="contactPhone" class="form-control"
								placeholder="Enter Your Mobile Number" formControlName="phone">
						</div>
						<div *ngIf="submitted && fGetInTouch.phone.errors" class="text-danger">
							<div *ngIf="fGetInTouch.phone.errors.required">Phone is required</div>
							<div *ngIf="fGetInTouch.phone.errors.pattern">Only Numbers are Allowed</div>
						</div>
						<div class="form-group">
							<textarea id="contactMessage" placeholder="Enter Your Message"
								formControlName="message"></textarea>
						</div>
						<div *ngIf="submitted && fGetInTouch.message.errors" class="text-danger">
							<div *ngIf="fGetInTouch.message.errors.required">Message is required</div>
						</div>
						<div class="form-group">
							<input type="submit" value="Send Message" [disabled]="disableSubmitButton">
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</div>

<!-- contact us modal -->
<div class="modal fade" id="successfullyMessageModal" tabindex="-1" role="dialog"
	aria-labelledby="successfullyMessageModal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="text-center px-3">
				<h5 class="modal-title my-3" id="exampleModalLabel">{{modalAlertMessage}}</h5>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn button2" data-dismiss="modal"
					(click)="refreshForm()">OK</button>
			</div>
		</div>
	</div>
</div>
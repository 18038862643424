<section class="mainveh_wrapper">
   <div class="container clientebooking_info">
      <h2>Driver e-Mail Confirmation</h2>
      <div class="row ">
         <div class="col-md-7 col-lg-6">
            <span><strong>Subject:</strong>New 1-800-LIMO.COM Booking</span>
            <h3>Booking #: 4585605464</h3>
         </div>
         <div class="col-md-5 col-lg-6">
            <img src="assets/admin/images/logo-login.png">
         </div>
         <div class="col-md-12 driver_rejectbook_info">
            <p>Verify confirmation is accurate. Drivers must arrive 15 min. after all domestic and 30 min. after
               international flights to minimize waiting time. If the passenger pays cash, the driver must give a
               receipt. If the base rate or other information is questionable DO NOT dispatch a driver without
               contacting 1-800-LIMO.COM (1-800-5466-266) before the service date. We only pay on your quoted
               All-Inclusive Rates: waiting time, additional parking, extra stops and tolls can be added when finalizing
               this booking. </p>
            <p><label class="text-danger">Reject</label> this booking if: </p>
            <ul>
               <li>You do not have this vehicle type and driver available,</li>
               <li>The rate is incorrect,</li>
               <li>You are not legal to do this specific booking, or</li>
               <li>You cannot be on-time.</li>
            </ul>
            <h5>If you would like to <label class="text-success">Accept</label> the reservation</h5>
            <h5>If you would like to <label class="text-danger">Reject</label> the reservation</h5>
         </div>
         <ul>
            <li>
               <h4>All-Inclusive ﻿Rate*</h4>
               <h4>
                  Hours <label>0</label> x Rate* <label>$0</label>
               </h4>
            </li>
            <li><label>$0</label> <label>$0</label></li>
         </ul>
      </div>
   </div>
   <div class="container">
      <div class=" emailconfirm_servtype">
         <h2>Date / Service Type</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Status:</p>
               <label class="col-md-8 text-danger">Pending </label>
            </li>
            <li>
               <p class="col-md-4">Service Type:</p>
               <label class="col-md-8"></label>
            </li>
            <li>
               <p class="col-md-4">Transfer Type:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4">Travel Type:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4">Pickup Date:</p>
               <label class="col-md-8">03/30/2020 | 30Mar2020 </label>
            </li>
            <li>
               <p class="col-md-4">Pickup Time:</p>
               <label class="col-md-8">10:00am | 1000 </label>
            </li>
         </ul>
         <div class="row mb-5">
            <div class="col-md-6 ">
               <div class="emailconfirm_pickdrop">
                  <h2>Pickup</h2>
                  <ul class="">
                     <li>
                        <p class="col-md-6 col-lg-4">Pickup Address: :</p>
                        <label class="col-md-6 col-lg-8">I-43, Elkhorn, WI 53121, USA</label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Airport:</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Airline Name::</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Flight Number:</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Origin Airport / City:</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-md-6 ">
               <div class="emailconfirm_pickdrop">
                  <h2>Final Drop Off</h2>
                  <ul class="">
                     <li>
                        <p class="col-md-6 col-lg-4">Drop Address:</p>
                        <label class="col-md-6 col-lg-8">I-43, Elkhorn, WI 53121, USA</label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Airport :</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Airline Name::</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                     <li>
                        <p class="col-md-6 col-lg-4">Flight Number:</p>
                        <label class="col-md-6 col-lg-8"> </label>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="col-md-12  emailconf_pickbotom">
               <p>Extra Stop # 2:</p>
               <p>Extra Stop # 3:</p>
               <p>Meet and Greet Choices: <strong>Driver - Call On Location on all meet and greet choices</strong></p>
               <br>
               <p>Special Requests:</p>
               <label>Please pick up at the Center for Translational Research and Education Building (green/glass bldg)
                  next to Stritch School of Medicine.</label>
            </div>
         </div>
         <h2>Driver</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Driver Name:</p>
               <label class="col-md-8">Dale A. </label>
            </li>
            <li>
               <p class="col-md-4">Driver Gender: </p>
               <label class="col-md-8">Male </label>
            </li>
            <li>
               <p class="col-md-4">Star Rating:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4">Background:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4">Driver Dress: </p>
               <label class="col-md-8">Suit and Tie </label>
            </li>
            <li>
               <p class="col-md-4">Driver Language: </p>
               <label class="col-md-8">English </label>
            </li>
            <li>
               <p class="col-md-4">Years Experience </p>
               <label class="col-md-8">6 </label>
            </li>
         </ul>
         <h2>Vehicle Type</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Vehicle Type:</p>
               <label class="col-md-8">Wagon </label>
            </li>
            <li>
               <p class="col-md-4">Vehicle Make: </p>
               <label class="col-md-8">2011 </label>
            </li>
            <li>
               <p class="col-md-4">Vehicle Model:</p>
               <label class="col-md-8">Lincoln Town Car </label>
            </li>
            <li>
               <p class="col-md-4">Vehicle Color:</p>
               <label class="col-md-8">Black </label>
            </li>
            <li>
               <p class="col-md-4">License Plate #: </p>
               <label class="col-md-8"> </label>
            </li>
         </ul>
      </div>
   </div>
   <!----AMENITIES Details---->
   <div class="container amenites_wrapper">
      <h2>Amenities </h2>
      <div class="amenites_wrapperin">
         <label>Amenities</label>
         <ul>
            <li>Luxury Model</li>
            <li>EV/Hybrid </li>
            <li>Jet Sprinter </li>
            <li>4-Wheel Drive </li>
            <li>Excutive </li>
            <li>Forward Facing </li>
            <li>Captain's Chair </li>
            <li>Warp Around- Limo </li>
            <li>Deluxe Touring </li>
         </ul>
      </div>
   </div>
   <div class="container">
      <div class=" emailconfirm_servtype">
         <h2>Passengers Details</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Pax Name:</p>
               <label class="col-md-8">James Simpkins </label>
            </li>
            <li>
               <p class="col-md-4"># of Pax:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4"># of Luggage:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4">Pax Cell#:</p>
               <label class="col-md-8">1-817-1269-749 </label>
            </li>
            <li>
               <p class="col-md-4">Other Contact#:</p>
               <label class="col-md-8">1-817-3129-697 1</label>
            </li>
         </ul>
      </div>
   </div>
   <!----pickup drop Details---->
   <div class="container ">
      <div class="row ">
         <!----------NOTE--------->
         <div class="col-md-12 emailconfm_notes mt-2">
            <label>*Tolls, waiting time, extra stop and taxes may apply above the All-Inclusive Rate<br>
               *Charter/tours Hours may be longer depending on final drop off time. The minimum number of hours is
               charged for an earlier end time.
               <br><br>
               You may speak with the client if they call and make any changes. Contact Global Dispatch with any changes
               to amend any reservation on places, time and rate changes. Be flexible with your rates and cancellation
               policies to keep our clients coming back for more great service.
            </label>
            <p>Thank you, </p>
            <p>1-800-LIMO.COM</p>
            <p>Phone: 1-800-5466-266</p>
            <p>International Phone Number: (000)0017082056607</p>
            <p>info@1800limo.com</p>
            <br>
            <p><strong>Note:<a href="#">Click</a>to Open and Read Below – Company Policies and Procedures </strong></p>
         </div>
      </div>
   </div>
</section>
<div class="modal fade" id="photoInstructionsModal" tabindex="-1" role="dialog" aria-labelledby="photoInstructionsModal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			<div class="modal-body">
				<ul class="mb-0">
					<li>
						Take exterior photos between 8 AM and 10 AM or between 2 PM and 4 PM when the sun is over your shoulder.
					</li>
					<li>
						Choose attractive backgrounds and sell the experience (big homes, water, mountains, fancy hotels, or tourist hot spot).
					</li>
					<li>
						Take interior pictures on a cloudy day - eliminate all shadows.
					</li>
					<li>
						Take night shots of interiors with lights and lasers effects on.
					</li>
				</ul>
			</div>
			<div class="modal-footer py-0">
				<button type="button" class="custom-btn button2" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>
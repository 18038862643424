<div class="innerpage_wrapper">
   <div class="innerpage_topbanner">
      <h2>
         Investors
         <hr>
      </h2>
   </div>
   <section class="safety_wrapper">
      <div class="container">
         <div class="innpage_video">
            <iframe width="100%" height="500px" src="https://www.youtube.com/embed/mSLOtFhp1ks"
               title="YouTube video player" frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen></iframe>
         </div>
         <p>Joseph Anselmo, our Founder, knows the livery industry well. He has personally driven over 1 million
            customer miles in his 28 years in the livery industry. Starting with one vehicle in 1988, he has grown to
            more than 1000 affiliates on several continents today. </p>
         <p>Since beginning in the livery business, he recognized that the average traveler could not easily find or
            book a safe ride when they wanted or at the lowest price. Unless the customer did a lot of comparison
            shopping, they would never know if they were receiving the best prices and service. Joe’s solution was to
            have an online, one-stop-shop to search, compare and book with ease. And from that vision years ago,
            1-800-LIMO.COM was born.</p>
         <a class="" href="">1995 Motorola / Apple Proposal Letter</a>
      </div>
   </section>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservation-cancellation',
  templateUrl: './reservation-cancellation.component.html',
  styleUrls: ['./reservation-cancellation.component.scss']
})
export class ReservationCancellationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="innerpage_wrapper">
   <div class="innerpage_topbanner">
      <h2>
         Driver FAQ
         <hr>
      </h2>
   </div>
   <section class="safety_wrapper">
      <div class="container">
         <h3>What is 1-800-LIMO.COM?</h3>
         <p>1-800-LIMO.COM Is an online, global free-market ground transportation platform. Competition is good for
            everyone. Show your best profile. We designed our platform around great drivers with the nicest vehicles. We
            made it simple to use and to work similarly to Match.com and eBay Motors. We match great drivers with great
            customers who appreciate consistent quality, worry-free ground transportation at competitive, not cheap
            rates.</p>
         <h3> Who can join 1-800-LIMO.COM?</h3>
         <p>Any consumer or driver can join, but not everyone will be able to stay. Users and drivers will be rated by
            their friendliness and appreciation. We only want the best customers and drivers who enjoy the features,
            advantages and benefits that 1-800-LIMO.COM offers its members.</p>
         <h3>How do I join 1-800-LIMO.COM and is there a cost?</h3>
         <p> We are looking for only the best drivers with nicer, newer, larger vehicles. Our customers are looking for
            you! Keep your independence, set your own schedule and set your own rates. Keep your tips. You control your
            own rates and can change them in real-time depending on demand. There is no surge pricing. You can stay
            driving with us as long as you get a thumbs up rating from our travelers. 3 thumbs down ratings and you're
            out (or fix the problem). We earn a 25% commission on each booking and pay you direct deposit in 3 days. You
            can accept or reject any booking. You'll be able to do both on-demand and prearranged rides (you’ll earn
            more per ride) and see pickup and drop off locations when you get a new booking. There are no surprises
            working with 1-800-LIMO.COM. We’re all about keeping great drivers on the road doing what you do best! We’ll
            keep you happy and loyal.</p>
         <h3>Which documents do I need to fully register?</h3>
         <p>
            <b>Taxi, Limo, Gig Drivers</b>
         </p>
         <p>• Photo of Driver license.
            <br>• Photo of Head and Shoulder – You want to look your best and wear the clothes you wear for work. Dress
            professionally.
            <br>• Photos of your vehicle – front and rear side views, interior front and rear seating, front and rear
            view of specialty vehicles (vans, minibuses, party buses, motor coaches.
            <br>• Photo of rear license plate.
            <br>• Photo of Proof of Insurance – add 1-800-LIMO.COM as additional insurance.
            <br>• Photo of Permits - city license, state licenses (if applicable), window sticker permits for airports
            and sea ports.
            <br>• Gig Drivers
            <br>o All gig drivers need proof of finger print document, city license.
            <br>o Proof of 4.5 – 5-star rating screen from your Uber or Lyft app.
            <br>o Gig drivers must have a minimum of three months driving experience.
         </p>
         <p>Fleet/Coach Operators&nbsp;</p>
         <p>• Photo of State/City Permits
            <br>• ICC number (if applicable)
            <br>• Proof of insurance
            <br>• Pictures of vehicles
            <br>
         </p>
         <h3>What are the driver benefits?</h3>
         <p>1-800-LIMO.COM wants to improve your efficiencies and increase your income.&nbsp;</p>
         <p>• You’ll earn more per mile and per hour driving with us.
            <br>• With our free software and app, you can view your past, present and future bookings.You can also view
            your daily, weekly, monthly and annual income statement.
            <br>• We’ll send you business that fits your schedule. Set your own hours and rates. We work as a team.
            <br>• You can accept or reject any booking.
            <br>• We add a 20% gratuity to every booking, so you can keep your tips. Our customers know a competitive
            rate and will tip extra if they like you. The best compliment is if they ask for you again.
            <br>• We take a 25% commission.
            <br>• Be as competitive as the market demands. You can change your rates in real time.
            <br>• You’ll get paid in 3 business days after funds are collected from the client.
            <br>• You’ll get a 1099 at the end of the year.
            <br>• You are responsible for all local, state and federal taxes.
            <br>• Be friendly, stay in a good mood, be helpful with luggage and the door, appreciate their patronage,
            befriend the clients we send you and turn them into repeat customers.
            <br>
         </p>
         <h3>Will 1-800-LIMO use autonomous vehicles? </h3>
         <p>NO, but in we believe autonomous vehicles can serve a purpose in a limited capacity.</p>
         <h3>Is there any profit sharing or other benefits? </h3>
         <p>Not at this time.</p>
         <h3>What vehicle can I use?</h3>
         <p>You can use any 4-door vehicle for commercial use that is allowed by city ordinance and state law. See our
            vehicle registration screen for a detailed list. The best vehicles are those that seat up to four passengers
            comfortably and carry a minimum of two large luggage.</p>
         <h3>How do I get new bookings?</h3>
         <p>We’ll send an email and a text alert for pre-arranged and on-demand bookings. You can either Accept or
            Reject without penalty.</p>
         <h3>How do I communicate with dispatch</h3>
         <p>Call 1-800-LIMO.COM (+1-800-5466.266) or text 708-205-6607.</p>
         <h3>How do I communicate with the customer?</h3>
         <p>You can text or call from the information on the reservation.</p>
         <h3>Do I need driver training? </h3>
         <p>No but it never hurts to refresh yourself by reading the “Rules of the Road” for your state. Gig drivers
            must have at least 3 months driving experience with Uber or Lyft and be rated 4.5 stars or higher to join
            1-800-LIMO.COM.</p>
         <h3>What is expected of me as a Back Car, Limo, Taxi, Gig or Fleet driver? </h3>
         <p>Honesty, integrity, professionalism and good behavior. You represent yourself first but also 1-800-LIMO.COM.
            Keep a neat appearance and keep your car clean. </p>
         <h3>Can I solicit the passenger for personal business?</h3>
         <p>No, drivers nor customers should solicit each other.</p>
         <h3>How much commission do I pay? </h3>
         <p>We take a 25% commission – taxes, tolls, and any other expenses are paid by the client to you directly. You
            are responsible for paying all local, state and federal taxes.</p>
         <h3>Do I keep my tips?</h3>
         <p>Yes, we automatically add a 20% gratuity to each booking. We will encourage our customers to hand tip extra
            for excellent service. Let your personality shine.</p>
         <h3>How are driver and customer disputes handled?</h3>
         <p>We are the mediator. We’ll look at both sides and decide. Email: Disputes@1800limo.com. We want quick action
            and expect any dispute to be resolved within 24 hours.</p>
         <h3>Is there surge pricing</h3>
         <p>No, there is no surge pricing.</p>
         <h3>What is the cancellation policy? </h3>
         <p>If you reject or cancel a booking within a 2-hour minimum, we will do our best to cover that booking for the
            same rate and vehicle and explain to the client. If we cannot cover that booking for the same rate and
            vehicle, you will be responsible for the difference in price to the clients. Do not accept a booking if you
            are going to be late or may have to cancel.</p>
         <h3>How and when do I get paid? </h3>
         <p>You will receive a direct deposit within 3 business days from the date funds are received from the client.
         </p>
         <h3>Can I reject a booking? </h3>
         <p>You can reject any booking at any time.</p>
         <h3>Why do I get rated by the customer? </h3>
         <p>We want you always to be at your best. Customers want consistency and worry-free travel</p>
         <h3>Can I be fired? </h3>
         <p>Yes, you and the client can “fire” each other.</p>
         <h3>Can I accept a booking then reject the same booking later? </h3>
         <p>Yes, you can accept and reject a booking but you must allow sufficient time for us to cover with another
            driver. If we cannot cover at the same price, you are responsible for the difference. If we cannot cover at
            all, you can be deleted as a driver.</p>
         <h3>Why do I have to give my social security number or EIN?</h3>
         <p>We need you SSN for proof of ID, for banking purposes and tax returns.</p>
      </div>
      <div id="ourfleet"> </div>
   </section>
</div>
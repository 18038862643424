<section class="adminbox_shadow user_information">
<div class="containeredd driverside_table">
    <div class="row">
        <div class="col-md-12 custom_table">
            <div class="table-responsive" style="min-height: 350px" >
                <table class="table table-dark table-striped table-bordered dataTable dt-responsive"
                    id="dataTable" role="grid" aria-describedby="dataTable_info" cellspacing="0">
                    <thead>
                        <tr role="row">
                            <th scope="col">#</th>
							<th scope="col">Type</th>
							<th scope="col">Transaction ID</th>
							<th scope="col">Refund Amount</th>
							<th scope="col" id="date">Created Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of paymentJson; let i=index">
							<th style="color: black;">{{i+1}}</th>
							<th scope="row" style="color:black">{{record.type}}</th>
							<td style="word-wrap: break-word;">{{record?.balance_transaction}}</td>
							<td><span class="currencySymbol">{{currencySymbol}}</span>
								{{(record?.amount).toFixed(2)}}</td>
							<td>{{TimestampToDate(record?.created)}}</td>
						</tr>
                    </tbody>
                </table>
            </div>
            <!-- <p class="text-danger text-lg" *ngIf="noError">
                No Results Found.
            </p> -->
        </div>
        <!----PAGINATION---->
        <!----BACK BTN---->
       
    </div>
</div>
</section>


<div class="modal fade bd-example-modal-lg" id="refundInfo" tabindex="-1" role="dialog"
	aria-labelledby="exampleModalLabel" aria-hidden="false">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content user_information bottom-container">
			<div class="modal-header pb-0">
				<h3 class="modal-title mb-0">Refund transaction</h3>
			</div>
			<div class="modal-body">
				<!-- {{invoiceData.audit_trail | json}} -->
				<table id="audittrail" class="table table-striped">
					<thead style="background-color: white;">
						<tr>
							<th scope="col">#</th>
							<th scope="col">Type</th>
							<th scope="col">Transaction ID</th>
							<th scope="col">Refund Amount</th>
							<th scope="col" id="date">Created Date</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let record of paymentJson; let i=index">
							<th scope="row">{{i+1}}</th>
							<th scope="row">{{record.type}}</th>
							<td style="word-wrap: break-word;">{{record?.balance_transaction}}</td>
							<td><span class="currencySymbol">{{currencySymbol}}</span>
								{{(record?.amount/100).toFixed(2)}}</td>
							<td>{{TimestampToDate(record?.created)}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="modal-footer justify-content-end">
				<button type="button" class="x-close-btn x-cross-btn mx-3" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" class="bi bi-x-circle"></span>
				</button>

			</div>
		</div>
	</div>
</div>
<form [formGroup]="addBankForm" class="step_form_wrapper" appInvalidControlScroll>
    <section class="user_information">
        <h2 class="headinginfo adminbox_shadow" *ngIf="response">Bank Account Status</h2>
        <div class="container">
            <div class="row" *ngIf="response">
                <div class="col-md-12" *ngIf="response.data.stripeDetail.transfer_status!='active'">
                    <p class="text-danger margin-bottom-thin my-mat-hint" style="font-size: 17px; font-weight: 800;">
                        Stripe
                        Activation can take upto 24 Hours.</p>
                </div>
                <div class="col-md-12" *ngIf="response.data?.stripeDetail?.stripe_errors">
                    <p class="text-danger margin-bottom-thin my-mat-hint" style="font-size: 17px; font-weight: 800;">
                        {{TaxIdMatch}}</p>
                </div>
                <div class="col-md-4">
                    <p [className]="'defaultStatus ' + response.data.stripeDetail.additional_doc_verification_status">
                        <strong>Stripe Doc Verification Status:</strong> <span class="text-capitalize">
                            {{response.data.stripeDetail.additional_doc_verification_status}}</span>
                    </p>
                </div>
                <div class="col-md-4">
                    <p [className]="'defaultStatus ' + response.data.stripeDetail.stripe_address_status">
                        <strong>Stripe Address Status:</strong> <span class="text-capitalize">
                            {{response.data.stripeDetail.stripe_address_status}}</span>
                    </p>
                </div>
                <div class="col-md-4">
                    <p [className]="'defaultStatus ' + response.data.stripeDetail.transfer_status">
                        <strong>Stripe Transfer Type:</strong> <span class="text-capitalize">
                            {{response.data.stripeDetail.transfer_status}}</span>
                    </p>
                </div>
                <div class="col-md-12" *ngIf="stripeErrors.length>0">
                    <h5 class="stripeErrorHeading">
                        Please fix these errors for the Payouts: <span *ngIf="ssnErrorMessage"
                            class="stripeErrorHeading">(SSN /
                            ID
                            NUMBER)</span>
                    </h5>
                    <ul>
                        <li *ngFor="let error of stripeErrors" tabindex="-1" style="color: red; font-weight: 400;">
                            {{error.error_message}}</li>
                    </ul>
                </div>
                <div class="col-12" *ngIf="response.data.bankinfo.stripe_account_type=='standard'">
                    <button class="forstep_nextveh" data-toggle="modal" data-target="#verificationDocumentModal"
                        (click)="stripeRefreshAccountLink()" tabindex="-1"><i class="fa fa-pencil-square-o"
                            aria-hidden="true"></i>
                        Edit Bank Information on Stripe
                    </button>
                </div>
            </div>
        </div>
        <h2 class="headinginfo adminbox_shadow">
            Company Bank Settings
            <span>(Per Stripe For Identification & Direct Deposits)</span>
        </h2>
        <div class="mt-1 container">
            <div class="row">
                <div class="col-md-6">
                    <!-- <mat-label>Bank Name <span class="asterisk">*</span></mat-label> -->
                    <mat-form-field appearance="outline">
                        <mat-label>Bank Name </mat-label>
                        <input matInput placeholder="Bank Name" id="BankName" formControlName="BankName"
                            (focus)="$event.target.select()" />
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Bank Address </mat-label>
                        <input matInput placeholder="Bank Address" formControlName="BankAddress"
                            (focus)="$event.target.select()" />
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Account Holder First Name<span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="Account Holder First Name*"
                            formControlName="AccountHolderFirstName" (focus)="$event.target.select()" />
                    </mat-form-field>
                    <div *ngIf="
							f.AccountHolderFirstName.errors &&
							(f.AccountHolderFirstName.touched ||
								f.AccountHolderFirstName.dirty)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.AccountHolderFirstName.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Account Holder Last Name<span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="Account Holder Last Name*" formControlName="AccountHolderLastName"
                            (focus)="$event.target.select()" />
                    </mat-form-field>
                    <div *ngIf="
							f.AccountHolderLastName.errors &&
							(f.AccountHolderLastName.touched ||
								f.AccountHolderLastName.dirty)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.AccountHolderLastName.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Account Number<span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="Account Number*" formControlName="AccountNumber"
                            (focus)="$event.target.select()" />
                    </mat-form-field>
                    <div *ngIf="
							f.AccountNumber.errors &&
							(f.AccountNumber.touched || f.AccountNumber.dirty)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.AccountNumber.errors.required">
                            This field is required
                        </div>
                        <!-- <div *ngIf="f.AccountNumber.errors.pattern">
                            This field should be a number
                        </div> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Checking Routing Number<span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="Checking Routing Number*" formControlName="Routing"
                            (focus)="$event.target.select()" />
                    </mat-form-field>
                    <div *ngIf="
							f.Routing.errors &&
							(f.Routing.touched || f.Routing.dirty)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.Routing.errors.required">
                            This field is required
                        </div>
                        <div *ngIf="f.Routing.errors.pattern">
                            This field should be a number
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Account Type
                            <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="AccountType">
                            <!-- <mat-option value="">Choose Account Type</mat-option> -->
                            <mat-option value="individual">Individual</mat-option>
                            <mat-option value="company">Business</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submittedForm && f.AccountType.errors"
                        class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.AccountType.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-4 birthMonth">
                    <mat-form-field appearance="outline" [ngClass]="{ 'border-red': dobErrorMessage }">
                        <mat-label>Month of Birth
                            <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="dobMonth" (selectionChange)="removeDobError()">
                            <mat-option value="">Month</mat-option>
                            <mat-option value="01">01</mat-option>
                            <mat-option value="02">02</mat-option>
                            <mat-option value="03">03</mat-option>
                            <mat-option value="04">04</mat-option>
                            <mat-option value="05">05</mat-option>
                            <mat-option value="06">06</mat-option>
                            <mat-option value="07">07</mat-option>
                            <mat-option value="08">08</mat-option>
                            <mat-option value="09">09</mat-option>
                            <mat-option value="10">10</mat-option>
                            <mat-option value="11">11</mat-option>
                            <mat-option value="12">12</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="dobErrorMessage" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="dobErrorMessage" style="font-weight: 550;">{{dobErrorMessage}}</div>
                    </div>
                    <div *ngIf="submittedForm && f.dobMonth.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.dobMonth.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-4 birthDay">
                    <mat-form-field appearance="outline" [ngClass]="{ 'border-red': dobErrorMessage }">
                        <mat-label>Day of Birth
                            <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="dobDay" (selectionChange)="removeDobError()">
                            <mat-option value="">Day</mat-option>
                            <mat-option *ngFor="let day of dobDay" value="{{ day }}">{{ day }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="dobErrorMessage" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="dobErrorMessage" style="font-weight: 550;">{{dobErrorMessage}}</div>
                    </div>
                    <div *ngIf="submittedForm && f.dobDay.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.dobDay.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-4 birthYear">
                    <mat-form-field appearance="outline" [ngClass]="{ 'border-red': dobErrorMessage }">
                        <mat-label>Year of Birth
                            <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="dobYear" (selectionChange)="removeDobError()">
                            <mat-option value="">Year</mat-option>
                            <mat-option *ngFor="let year of dobYear" value="{{ year }}">{{ year }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="dobErrorMessage" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="dobErrorMessage" style="font-weight: 550;">{{dobErrorMessage}}</div>
                    </div>
                    <div *ngIf="submittedForm && f.dobYear.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.dobYear.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Currency <span class="asterisk">*</span></mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                            formControlName="currencyShow" [matAutocomplete]="auto"
                            (keyup)="handleCurrency($event.target.value)" (focus)="$event.target.select()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let currencyOption of currencyOptions"
                                [value]="currencyOption.currency + '-' + currencyOption.currencyCountry">
                                {{ currencyOption.countryName }} - {{ currencyOption.symbol }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline">
						<mat-label>Currency <span class="asterisk">*</span></mat-label>
						<mat-select formControlName="currency" tabindex="1">
							<mat-option value="">Choose Currency</mat-option>
							<mat-option *ngFor="let currencyOption of currencyOptions"
								(onSelectionChange)="selectCurrency(option,$event.isUserInput)"
								[value]="currencyOption.currency + '-' + currencyOption.currencyCountry">
								{{ currencyOption.countryName }} - {{ currencyOption.symbol }}
							</mat-option>
						</mat-select>
					</mat-form-field> -->

                    <div *ngIf="submittedForm && f.currency.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.currency.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline"
                        [ngClass]="{ 'disableSsn': enableSsnField, 'border-red': ssnErrorMessage }">
                        <mat-label>SSN (USA required by STRIPE / Government ID for other countries)
                            <span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="SSN (USA required by STRIPE / Government ID for other countries)"
                            formControlName="ssn" tabindex="1" (focus)="$event.target.select()"
                            (change)="handleSsnInput($event.target.value)"
                            (keyup)="removeErrorSsn($event.target.value,'ssn')" />
                    </mat-form-field>
                    <div *ngIf="ssnErrorMessage" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="ssnErrorMessage" style="font-weight: 550;">{{ssnErrorMessage}}</div>
                    </div>
                    <div *ngIf="
							(submittedForm && f.ssn.errors) ||
							(f.ssn.touched && f.ssn.errors)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.ssn.errors.required">
                            This field is required
                        </div>
                    </div>
                    <div *ngIf="f.ssn.errors" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="
								f.ssn.errors?.invalid_Pattern;
								else plusError
							">
                            Do not add Dashes with numbers
                        </div>
                        <ng-template #plusError>
                            <div *ngIf="
									f.ssn.errors?.invalidPattern;
									else allErrors
								">
                                Do not enter + sign
                            </div>
                            <ng-template #allErrors>
                                <div *ngIf="f.ssn.errors?.pattern">
                                    Enter Numbers Only
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
                <div class="col-md-6 mt-3 radioLabel">
                    <div id="ein-container" class="w-100 d-flex flex-nowrap justify-content-center">
                        <div id="ein-no" class="ein" [ngClass]="!f.haveEin.value ? 'active' : ''"
                            (click)="changeRadio('haveEin', false)">
                            <label>I don't have EIN / Business ID #</label>
                        </div>
                        <div id="ein-yes" class="ein" [ngClass]="f.haveEin.value ? 'active' : ''"
                            (click)="changeRadio('haveEin', true)">
                            <label>I have an EIN / Business ID #</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3" *ngIf="f.haveEin.value">
                    <mat-form-field appearance="outline">
                        <mat-label>EIN / Business ID #
                            <span class="asterisk">*</span></mat-label>
                        <input matInput placeholder="EIN / Business ID #" (focus)="$event.target.select()"
                            formControlName="ein" tabindex="1" (focus)="$event.target.select()" />
                    </mat-form-field>
                    <div *ngIf="
							(submittedForm && f.ein.errors) ||
							(f.ein.touched && f.ein.errors)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.ein.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="user_information">
        <h2 class="headinginfo adminbox_shadow">
            Owner Company Identity Information
        </h2>
        <div class="container">
            <div class="vehtype_extintwrap">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button type="button" class="verifyDocButton box-shadow" data-toggle="modal"
                            data-target="#verificationDocumentModal" tabindex="1">
                            Acceptable Country Owner Verification Document
                        </button>
                    </div>
                    <div class="col-md-5" *ngIf="id_front_image; else no_id_front_image">
                        <div class="vehtype_extint">
                            <div>
                                <p>
                                    Drivers License or Other ID
                                    <span class="optionalTextStyle">(Front Photo)</span>
                                    <span class="asterisk">*</span>
                                </p>
                                <img src="{{ id_front_image }}" (click)="showImageInModal(id_front_image)"
                                    tabindex="-1" />
                                <!-- *ngIf="canChangeDocument" -->
                                <ul class="mt-3">
                                    <li>
                                        <a class="custom-arrow-clockwise-button" (click)="
												fetchImageBlob(
													id_front_image,
													'id_front_image',
													id_front_image_id
												)
											">
                                            <i class="bi bi-arrow-clockwise"></i>rotate</a>
                                    </li>
                                    <li>
                                        <button class="changeuploaded" tabindex="1">
                                            <input type="file" (change)="
													idCardImageChange(
														$event,
														'id_front_image',
														id_front_image_id
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path fill="none" d="M0 0h24v24H0V0z" />
                                                <circle cx="12" cy="12" r="3" />
                                                <path
                                                    d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                            </svg>
                                            Change
                                        </button>
                                    </li>
                                    <li>
                                        <a class="delete-btn" (click)="
												deleteImage(
													id_front_image_id,
													'id_front_image'
												)
											" tabindex="1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                <path
                                                    d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
                                            </svg>
                                            Delete
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ng-template #no_id_front_image>
                        <div class="col-md-5">
                            <div class="vehtype_extint">
                                <div>
                                    <p [class.ng-invalid]="
											submittedForm &&
											f.id_front_image.errors.required
										">
                                        Drivers License or Other ID
                                        <span class="optionalTextStyle">(Front Photo)</span>
                                        <span class="asterisk">*</span>
                                    </p>
                                    <div class="emptyImage">
                                        <img src="../../../../assets/images/empty-image.png" tabindex="-1" />
                                    </div>
                                    <!-- ngif to allow user to change the image or add----- canChangeDocument -->
                                    <ul *ngIf="true" class="mt-3">
                                        <li>
                                            <button class="adduploaded" tabindex="1">
                                                <input type="file" (change)="
														idCardImageChange(
															$event,
															'id_front_image'
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path fill="none" d="M0 0h24v24H0V0z" />
                                                    <circle cx="12" cy="12" r="3" />
                                                    <path
                                                        d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                                </svg>
                                                Add
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="
										submittedForm && f.id_front_image.errors
									" class="text-danger margin-bottom-thin my-mat-hint mb-4">
                                    <div *ngIf="f.id_front_image.errors.required">
                                        The Drivers License or Other ID (Front
                                        Photo) is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="col-md-5" *ngIf="id_back_image; else no_id_back_image">
                        <div class="vehtype_extint">
                            <div>
                                <p>
                                    Drivers License or Other ID
                                    <span class="optionalTextStyle">(Back Photo)</span>
                                </p>
                                <img src="{{ id_back_image }}" (click)="showImageInModal(id_back_image)"
                                    tabindex="-1" />
                                <!-- *ngIf="canChangeDocument" -->
                                <ul class="mt-3">
                                    <li>
                                        <a class="custom-arrow-clockwise-button" (click)="
												fetchImageBlob(
													id_back_image,
													'id_back_image',
													id_back_image_id
												)
											">
                                            <i class="bi bi-arrow-clockwise"></i>rotate</a>
                                    </li>
                                    <li>
                                        <button class="changeuploaded" tabindex="1">
                                            <input type="file" (change)="
													idCardImageChange(
														$event,
														'id_back_image',
														id_back_image_id
													)
												" tabindex="-1" accept="image/jpeg, image/png" />
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path fill="none" d="M0 0h24v24H0V0z" />
                                                <circle cx="12" cy="12" r="3" />
                                                <path
                                                    d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                            </svg>
                                            Change
                                        </button>
                                    </li>
                                    <li>
                                        <a class="delete-btn" (click)="
												deleteImage(
													id_back_image_id,
													'id_back_image'
												)
											" tabindex="1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                <path
                                                    d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
                                            </svg>
                                            Delete
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ng-template #no_id_back_image>
                        <div class="col-md-5">
                            <div class="vehtype_extint">
                                <div>
                                    <p>
                                        Drivers License or Other ID
                                        <span class="optionalTextStyle">(Back Photo)</span>
                                    </p>
                                    <div class="emptyImage">
                                        <img src="../../../../assets/images/empty-image.png" tabindex="-1" />
                                    </div>
                                    <!-- ngif to allow user to change the image or add----- canChangeDocument -->
                                    <ul *ngIf="true" class="mt-3">
                                        <li>
                                            <button class="adduploaded" tabindex="1">
                                                <input type="file" (change)="
														idCardImageChange(
															$event,
															'id_back_image',
															id_back_image_id
														)
													" tabindex="-1" accept="image/jpeg, image/png" />
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path fill="none" d="M0 0h24v24H0V0z" />
                                                    <circle cx="12" cy="12" r="3" />
                                                    <path
                                                        d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                                                </svg>
                                                Add
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </section>

    <section class="user_information">
        <h2 class="headinginfo adminbox_shadow">Company Address information</h2>
        <div class="container mt-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="addveh_top d-md-flex justify-content-between align-items-center mb-0">
                        <label class="alert alert-danger" style="color: red !important;">Enter Business Address and
                            Choose From Drop Down.</label>
                        <!-- <label class="alert alert-danger my-2 mx-2">Choose nearest major city or airport
							location</label> -->
                        <label></label>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline" [ngClass]="{ 'border-red': addressErrorMessage }">
                        <mat-label>Enter Business/Base Address</mat-label>
                        <input matInput placeholder="Search Here and Set Location Pin" formControlName="address"
                            #search1 tabindex="1" (focus)="$event.target.select()"
                            (keyup)="removeErrorSsn($event.target.value,'address')" />
                    </mat-form-field>
                    <div *ngIf="addressErrorMessage" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="addressErrorMessage" style="font-weight: 550;">{{addressErrorMessage}}</div>
                    </div>
                    <div *ngIf="
							(submittedForm && f.address.errors) ||
								(f.address.touched && f.address.errors);
							else latLongError
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.address.errors.required">
                            This field is required
                        </div>
                    </div>
                    <ng-template #latLongError>
                        <div *ngIf="
								(submittedForm && f.latitude.errors) ||
								(f.latitude.touched && f.latitude.errors)
							" class="text-danger margin-bottom-thin my-mat-hint">
                            <div *ngIf="f.latitude.errors.required">
                                Please enter valid address.
                            </div>
                        </div>
                    </ng-template>
                </div>
                <!-- <div class="col-md-4">
					<mat-form-field appearance="outline" class="example-full-width">
						<mat-label>Badge City </mat-label>
						<input type="text" placeholder="Pick one" aria-label="Number" matInput
							formControlName="badge_city_name" [matAutocomplete]="auto"
							(keyup)="handleBadgeCity($event.target.value)" />
						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
							<mat-option *ngFor="let option of filteredOptions" (onSelectionChange)="
									selectBadgeCity(option, $event.isUserInput)
								" [value]="option.name">
								{{ option.name }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div> -->
                <div class="col-md-12 map-wrapper" *ngIf="latitude">
                    <agm-map [latitude]="latitude" [longitude]="longitude">
                        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true">
                        </agm-marker>
                    </agm-map>
                </div>
                <div class="col-md-12 mt-3">
                    <h3 class="addveh_top d-md-flex justify-content-between align-items-center mb-0">
                        <label class="alert alert-danger" style="color: red !important;">Verify All Fields Under the Map
                            - Especially -
                            Complete Address and City.</label>
                    </h3>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Country <span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="country" (selectionChange)="changeCountry($event.value)"
                            tabindex="1">
                            <mat-option value="">Choose Country</mat-option>
                            <mat-option *ngFor="let countryOption of countryOptions"
                                [value]="countryOption.countryShortCode">
                                {{ countryOption.countryName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Country <span class="asterisk">*</span></mat-label>
                        <input formControlName="country" type="text" matInput placeholder="Country"
                            (focus)="$event.target.select()">
                    </mat-form-field> -->
                    <div *ngIf="
							(submittedForm && f.country.errors) ||
							(f.country.touched && f.country.errors)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.country.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label
							>State/Province
							<span class="asterisk">*</span></mat-label
						>
						<mat-select formControlName="state" tabindex="1">
							<mat-option value=""
								>Choose State/Province</mat-option
							>
							<mat-option
								*ngFor="let stateOption of stateOptions"
								[value]="stateOption.shortCode"
							>
								{{ stateOption.name }}</mat-option
							>
						</mat-select>
					</mat-form-field>
					<div
						*ngIf="
							(submittedForm && f.state.errors) ||
							(f.state.touched && f.state.errors)
						"
						class="text-danger margin-bottom-thin my-mat-hint"
					>
						<div *ngIf="f.state.errors.required">
							This field is required
						</div>
					</div>
				</div> -->
                <!-- <div class="col-md-6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Street <span class="asterisk">*</span></mat-label>
                                                    <input matInput placeholder="Street" formControlName="street" disabled tabindex="1">
                                                </mat-form-field>
                                                <div *ngIf="submittedForm && f.street.errors || f.street.touched && f.street.errors"
                                                    class="text-danger margin-bottom-thin my-mat-hint">
                                                    <div *ngIf="f.street.errors.required">This field is required</div>
                                                </div>
                                            </div> -->
                <!-- <div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label
							>City <span class="asterisk">*</span></mat-label
						>
						<input
							matInput
							placeholder="City"
							formControlName="city"
							disabled
							tabindex="1"
						/>
					</mat-form-field>
					<div
						*ngIf="
							(submittedForm && f.city.errors) ||
							(f.city.touched && f.city.errors)
						"
						class="text-danger margin-bottom-thin my-mat-hint"
					>
						<div *ngIf="f.city.errors.required">
							This field is required
						</div>
					</div>
				</div> -->
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit#</mat-label>
                        <input matInput placeholder="Unit" formControlName="unit" tabindex="1"
                            (focus)="$event.target.select()" />
                    </mat-form-field>
                    <div *ngIf="
							(submittedForm && f.unit.errors) ||
							(f.unit.touched && f.unit.errors)
						" class="text-danger margin-bottom-thin my-mat-hint">
                        <div *ngIf="f.unit.errors.required">
                            This field is required
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label
							>Zip Code <span class="asterisk">*</span></mat-label
						>
						<input
							matInput
							placeholder="Zip Code"
							formControlName="zipCode"
							tabindex="1"
						/>
					</mat-form-field>
					<div
						*ngIf="
							(submittedForm && f.zipCode.errors) ||
							(f.zipCode.touched && f.zipCode.errors)
						"
						class="text-danger margin-bottom-thin my-mat-hint"
					>
						<div *ngIf="f.zipCode.errors.required">
							This field is required
						</div>
					</div>
					<div
						*ngIf="f.zipCode.errors"
						class="text-danger margin-bottom-thin my-mat-hint"
					>
						<div *ngIf="f.zipCode.errors?.pattern">
							Enter Numbers Only
						</div>
					</div>
				</div> -->
            </div>
        </div>
    </section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="billing_inforamtion">
                    <ul class=" d-flex justify-content-center">
                        <li><button class="custom-filled-reset-button mr-2" (click)="resetForm()"
                                tabindex="1">RESET</button> </li>
                        <li><button class="custom-filled-button mr-2" (click)="submitForm()"
                                [disabled]="disableSubmitButton" tabindex="1">SAVE</button> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Country wise Document modal -->
<div class="modal fade" id="verificationDocumentModal" tabindex="-1" role="dialog"
    aria-labelledby="verificationDocumentModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content user_information">
            <button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header">
                <h3 class="modal-title mb-0" id="exampleModalLabel">
                    Click Here: Acceptable Country Owner Verification Document
                </h3>
            </div>
            <div class="modal-body">
                <mat-form-field appearance="outline">
                    <mat-label>Country <span class="asterisk">*</span></mat-label>
                    <mat-select (selectionChange)="changeIdentityCountry($event.value)">
                        <mat-option value="">Choose Country</mat-option>
                        <mat-option value="AR">Argentina</mat-option>
                        <mat-option value="AU">Australia</mat-option>
                        <mat-option value="AT">Austria</mat-option>
                        <mat-option value="BE">Belgium</mat-option>
                        <mat-option value="BO">Bolivia</mat-option>
                        <mat-option value="BR">Brazil</mat-option>
                        <mat-option value="BG">Bulgaria</mat-option>
                        <mat-option value="CA">Canada</mat-option>
                        <mat-option value="CL">Chile</mat-option>
                        <mat-option value="CO">Colombia</mat-option>
                        <mat-option value="HR">Croatia</mat-option>
                        <mat-option value="CY">Cyprus</mat-option>
                        <mat-option value="CZ">Czech Republic</mat-option>
                        <mat-option value="DK">Denmark</mat-option>
                        <mat-option value="DO">Dominican Republic</mat-option>
                        <mat-option value="EG">Egypt</mat-option>
                        <mat-option value="EE">Estonia</mat-option>
                        <mat-option value="FI">Finland</mat-option>
                        <mat-option value="FR">France</mat-option>
                        <mat-option value="DE">Germany</mat-option>
                        <mat-option value="GR">Greece</mat-option>
                        <mat-option value="HK">Hong Kong</mat-option>
                        <mat-option value="HU">Hungary</mat-option>
                        <mat-option value="IN">India</mat-option>
                        <mat-option value="IS">Iceland</mat-option>
                        <mat-option value="ID">Indonesia</mat-option>
                        <mat-option value="IE">Ireland</mat-option>
                        <mat-option value="IL">Israel</mat-option>
                        <mat-option value="IT">Italy</mat-option>
                        <mat-option value="JP">Japan</mat-option>
                        <mat-option value="LV">Latvia</mat-option>
                        <mat-option value="LI">Liechtenstein</mat-option>
                        <mat-option value="LT">Lithuania</mat-option>
                        <mat-option value="LU">Luxembourg</mat-option>
                        <mat-option value="MY">Malaysia</mat-option>
                        <mat-option value="MT">Malta</mat-option>
                        <mat-option value="MX">Mexico</mat-option>
                        <mat-option value="NL">Netherlands</mat-option>
                        <mat-option value="NZ">New Zealand</mat-option>
                        <mat-option value="NO">Norway</mat-option>
                        <mat-option value="PY">Paraguay</mat-option>
                        <mat-option value="PE">Peru</mat-option>
                        <mat-option value="PL">Poland</mat-option>
                        <mat-option value="PT">Portugal</mat-option>
                        <mat-option value="RO">Romania</mat-option>
                        <mat-option value="SG">Singapore</mat-option>
                        <mat-option value="SK">Slovakia</mat-option>
                        <mat-option value="SI">Slovenia</mat-option>
                        <mat-option value="ES">Spain</mat-option>
                        <mat-option value="SE">Sweden</mat-option>
                        <mat-option value="CH">Switzerland</mat-option>
                        <mat-option value="TT">Trinidad &amp; Tobago</mat-option>
                        <mat-option value="AE">United Arab Emirates</mat-option>
                        <mat-option value="GB">United Kingdom</mat-option>
                        <mat-option value="US" selected>United States</mat-option>
                        <mat-option value="UY">Uruguay</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="identityDocuments">
                    <p>Identity Documents</p>
                </div>
                <ul>
                    <li *ngFor="let Document of countryDocumentsArray">
                        <span *ngIf="Document.value.length == 3">
                            {{ Document.value[1].value }} ({{
                            Document.value[0].value
                            }})
                            {{
                            Document.value[2].value
                            ? "-scans of front and back are required"
                            : ""
                            }}
                        </span>
                        <span *ngIf="Document.value.length == 2">
                            {{ Document.value[0].value }}
                            {{
                            Document.value[1].value
                            ? "- scans of front and back are required"
                            : ""
                            }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="custom-btn button2" data-dismiss="modal" tabindex="-1">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>
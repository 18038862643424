import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-email-confirmation',
  templateUrl: './driver-email-confirmation.component.html',
  styleUrls: ['./driver-email-confirmation.component.scss']
})
export class DriverEmailConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-licensing',
  templateUrl: './insurance-licensing.component.html',
  styleUrls: ['./insurance-licensing.component.scss']
})
export class InsuranceLicensingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

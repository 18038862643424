<div class="affilate-wrapper admin-wrapper">
    <ngx-spinner></ngx-spinner>
    <nav id="sidenavAccordion" class="topnav navbar navbar-expand">
        <a class="navbar-brand active" routerLink="/" tabindex="-1">
            <img src="assets/admin/images/new_logo.png">
        </a>
        <button class="btn btn-icon btn-transparent-dark mr-lg-2" id="sidebarToggle" tabindex="-1"
            style="background: none !important;">
            <div class="hamburger" *ngIf="showSidebar===true" (click)="showSidebarFunc(false)">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>

            <div class="hamburger" *ngIf="showSidebar===false" (click)="showSidebarFunc(true)">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
            <!-- <svg *ngIf="showSidebar===true" xmlns="http://www.w3.org/2000/svg" (click)="showSidebarFunc(false)"
				width="24" height="24" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
				<path fill-rule="evenodd"
					d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
			</svg>

			<svg (click)="showSidebarFunc(true)" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
				fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16" *ngIf="showSidebar===false">
				<path fill-rule="evenodd"
					d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
			</svg> -->
        </button>
        <ul class="navbar-nav align-items-center ml-auto">
            <li class="hideMobile">
                <!-- <div id="google-translate">
					<div id="google_translate_element_desktop"></div>
				  </div> -->
                <div id="google_translate_element_desktop"></div>
            </li>
            <li class="nav-item dropdown no-caret dropdown-user">
                <a class="user-profile btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage"
                    href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="!profile_pic_url">
                        <path fill="#000" fill-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM5.99927 17C7.36758 15.1783 9.54609 14 11.9998 14C14.4535 14 16.6321 15.1783 18.0004 17C16.6321 18.8217 14.4535 20 11.9998 20C9.54609 20 7.36758 18.8217 5.99927 17Z"
                            clip-rule="evenodd" />
                    </svg>
                    <img src="{{profile_pic_url}}" height="24px" width="24px" *ngIf="profile_pic_url"
                        style="object-fit: contain;">
                    <span class="font-weight-bold">
                        {{currentUser?.first_name + ' ' + currentUser?.last_name }}
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
                    aria-labelledby="navbarDropdownUserImage">
                    <h6 class="user-profile dropdown-header d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            *ngIf="!profile_pic_url">
                            <path fill="#000" fill-rule="evenodd"
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM5.99927 17C7.36758 15.1783 9.54609 14 11.9998 14C14.4535 14 16.6321 15.1783 18.0004 17C16.6321 18.8217 14.4535 20 11.9998 20C9.54609 20 7.36758 18.8217 5.99927 17Z"
                                clip-rule="evenodd" />
                        </svg>
                        <img src="{{profile_pic_url}}" height="24px" width="24px" *ngIf="profile_pic_url"
                            style="object-fit: contain;">

                        <div class="dropdown-user-details">
                            <div class="dropdown-user-details-name font-weight-bold">
                                {{currentUser?.first_name + ' ' + currentUser?.last_name }}
                            </div>
                        </div>
                    </h6>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="logout()" tabindex="-1">
                        <div class="dropdown-item-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-log-out">
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                        </div>
                        Logout
                    </a>
                </div>
            </li>
        </ul>
    </nav>
    <mat-progress-bar class="mb-3 my-color" mode="indeterminate" *ngIf="progressBar">
    </mat-progress-bar>
    <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
            <nav class="sidenav shadow-right">
                <div class="sidenav-menu">
                    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                        <li class="nav-item">
                            <a class="nav-link collapsed" [routerLinkActive]="['active']"
                                routerLink="/affiliate_driver/my-bookings" (click)="closeSidebarFunc(false)"
                                tabIndex="-1">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 122.88 118.34">
                                    <defs>
                                        <style>
                                            .cls-1 {
                                                fill-rule: evenodd;
                                            }
                                        </style>
                                    </defs>
                                    <title>work-schedule</title>
                                    <path
                                        d="M95.53,63.65A27.35,27.35,0,1,1,68.19,91,27.35,27.35,0,0,1,95.53,63.65ZM71.59,4.05c0-2.23,2.21-4,4.94-4s4.94,1.82,4.94,4.05V22.9c0,2.24-2.21,4.05-4.94,4.05s-4.94-1.81-4.94-4.05V4.05Zm-44.26,0c0-2.23,2.21-4,4.94-4s4.95,1.82,4.95,4.05V22.9C37.22,25.14,35,27,32.27,27s-4.94-1.81-4.94-4.05V4.05ZM63.91,111.92H10.24A10.28,10.28,0,0,1,0,101.68V20.54A10.29,10.29,0,0,1,10.24,10.3h9.44V22.9a11.24,11.24,0,0,0,4.26,8.75,13.25,13.25,0,0,0,16.67,0,11.24,11.24,0,0,0,4.26-8.75V10.3H63.94V22.9a11.23,11.23,0,0,0,4.25,8.75,13.26,13.26,0,0,0,16.68,0,11.26,11.26,0,0,0,4.25-8.75V10.3H99a10.28,10.28,0,0,1,10.24,10.24V55.63a38.34,38.34,0,0,0-4.37-1.4V39.94H4.37V99.5a8.08,8.08,0,0,0,8.05,8h49a40.11,40.11,0,0,0,2.5,4.37ZM19.68,56.24l3.46,3.25,7.09-7.21c.73-.75,1.2-1.35,2.11-.41l3,3c1,1,.91,1.52,0,2.42L24.82,67.58c-1.92,1.89-1.59,2-3.55.07l-6.56-6.53a.85.85,0,0,1,.08-1.33l3.43-3.55c.51-.54.93-.51,1.46,0ZM48,51.71H62.68a1.87,1.87,0,0,1,1.87,1.86V65.78a1.89,1.89,0,0,1-1.87,1.87H48a1.88,1.88,0,0,1-1.87-1.87V53.57A1.88,1.88,0,0,1,48,51.71Zm29.59,0H92.27a1.89,1.89,0,0,1,1.81,1.4,37.79,37.79,0,0,0-18.35,5.55V53.57a1.87,1.87,0,0,1,1.87-1.86ZM48,77.66H60A37.81,37.81,0,0,0,57.62,91c0,.87,0,1.74.09,2.6H48a1.88,1.88,0,0,1-1.87-1.87V79.53A1.88,1.88,0,0,1,48,77.66Zm-29.58,0H33.1A1.87,1.87,0,0,1,35,79.53v12.2A1.89,1.89,0,0,1,33.1,93.6H18.43a1.87,1.87,0,0,1-1.87-1.87V79.53a1.87,1.87,0,0,1,1.87-1.87Zm73.31-.43h3.34a1.12,1.12,0,0,1,1.12,1.12V91.23H108a1.12,1.12,0,0,1,1.12,1.11v3.35A1.12,1.12,0,0,1,108,96.8H90.63V78.35a1.12,1.12,0,0,1,1.11-1.12Zm3.79-7.37A21.14,21.14,0,1,1,74.4,91,21.13,21.13,0,0,1,95.53,69.86Z" />
                                </svg>
                                <span style="color: #05b171;">View Daily Bookings</span>
                            </a>
                        </li>

                        <li>
                            <div id="google_translate_element_mobile"></div>
                        </li>
                        <hr class="sidebar-divider d-none d-md-block">
                        <div class="text-center d-none d-md-inline">
                            <button class="rounded-circle border-0" id="sidebarToggle" tabindex="-1"></button>
                        </div>
                    </ul>
                </div>
            </nav>
        </div>

        <div id="layoutSidenav_content" (click)="handleDivClick($event)">
            <main style="padding: inherit;">
                <!-- <h1 class="HeadingH1">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
							<rect fill="none" />
							<ellipse cx="128" cy="128" rx="24" ry="40" />
							<path
								d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24Zm0,160c-27.63281,0-40-28.125-40-56s12.36719-56,40-56,40,28.125,40,56S155.63281,184,128,184Z" />
						</svg>
						Agreement
					</div>
				</h1> -->
                <router-outlet></router-outlet>
            </main>
            <footer class="admin-footer">
                <div class="col-9 pl-0 mt-5">
                    <p>Copyright © 2001-{{currentYear}} 1-800-LIMO.COM</p>
                </div>
                <div class="col-3">
                </div>
            </footer>
        </div>


    </div>
</div>
<app-scroll-to-top></app-scroll-to-top>

<!-- <app-float-icons></app-float-icons> -->
<ngx-spinner></ngx-spinner>
<div class="partner_registration_wrapper">
    <div class="partner_reg_div">
        <form [formGroup]="registrationForm" appInvalidControlScroll>
            <section class="user_information">
                <h2 class="planDetails_heading adminbox_shadow">{{planName}}: $ {{planPrice}}</h2>
                <h2 class="headinginfo adminbox_shadow"> Register with us!</h2>

                <div class="container-fluid mt-1 ">
                    <div class="row ">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Company Name <span class="asterisk">*</span></mat-label>
                                <input formControlName="company_name" type="text" matInput placeholder="Company Name"
                                    (focus)="$event.target.select()">
                            </mat-form-field>
                            <div *ngIf="(submittedForm && f.company_name.errors) || (f.company_name.touched && f.company_name.errors)"
                                class="text-danger text-lg m-0">
                                <div *ngIf="f.company_name.errors.required">This field is required</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>First Name <span class="asterisk">*</span></mat-label>
                                <input formControlName="firstName" type="text" matInput placeholder="First Name"
                                    (focus)="$event.target.select()">
                            </mat-form-field>
                            <div *ngIf="(submittedForm && f.firstName.errors) || (f.firstName.touched && f.firstName.errors)"
                                class="text-danger text-lg m-0">
                                <div *ngIf="f.firstName.errors.required">This field is required</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Last Name <span class="asterisk">*</span></mat-label>
                                <input formControlName="lastName" type="text" matInput placeholder="Last Name"
                                    (focus)="$event.target.select()">
                            </mat-form-field>
                            <div *ngIf="(submittedForm && f.lastName.errors) || (f.lastName.touched && f.lastName.errors)"
                                class="text-danger text-lg m-0">
                                <div *ngIf="f.lastName.errors.required">This field is required</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="mobileLabelClass">
                                <label class="mat-form-field-label mat-empty mat-form-field-empty labelClass">Mobile
                                    <span class="asterisk">*</span></label>
                                <input matInput placeholder="Mobile" formControlName="phone" ng2TelInput
                                    [ng2TelInputOptions]="{'preferredCountries': ['us', 'ca','mx','gb']}"
                                    placeholder="1234567890" class="phoneNumberClass"
                                    (countryChange)="onCountryChange($event,'mobile')" (change)="onChangeMobile($event)"
                                    (intlTelInputObject)="telInputObjectMobile($event)" tabIndex="1"
                                    (focus)="$event.target.select()" />
                            </mat-form-field>
                            <!-- <div class="d-flex mt-2"
                                *ngIf="f?.phone?.touched && !f?.phone?.errors?.minlength && !f?.phone?.errors?.required && enableOtpField">
                                <a style="text-decoration: underline; color: #007bff; cursor: pointer;"
                                    (click)="sendOtp()">Please verify
                                    mobile number. Click to send Otp!</a>
                            </div> -->
                            <div *ngIf="submittedForm && f?.phone?.errors || f?.phone?.touched && f?.phone?.errors"
                                class="text-danger margin-bottom-thin my-mat-hint">
                                <div *ngIf="f?.phone?.errors?.required">This field is required</div>
                            </div>
                            <div *ngIf="f?.phone?.errors" class="text-danger margin-bottom-thin my-mat-hint">
                                <div *ngIf="f?.phone?.errors?.invalid_Pattern;else plusError">Do not add
                                    Dashes
                                    with numbers</div>
                                <ng-template #plusError>
                                    <div *ngIf="f?.phone?.errors?.invalidPattern;else allErrors">Do not enter
                                        +
                                        sign</div>
                                    <ng-template #allErrors>
                                        <div *ngIf="f?.phone?.errors?.pattern;else numberLength">Enter Numbers
                                            Without
                                            Spaces or Dashes
                                        </div>
                                        <ng-template #numberLength>
                                            <div *ngIf="f?.phone?.errors?.minlength">Minimum length should be
                                                9</div>
                                            <div *ngIf="f?.phone?.errors?.maxlength">Maximum length should be
                                                15</div>
                                        </ng-template>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>E-mail <span class="asterisk">*</span></mat-label>
                                <input formControlName="email" type="email" matInput placeholder="E-mail"
                                    (focus)="$event.target.select()">
                            </mat-form-field>
                            <div *ngIf="(submittedForm && f.email.errors) || (f.email.touched && f.email.errors)"
                                class="text-danger text-lg m-0">
                                <div *ngIf="f.email.errors.required">This field is required</div>
                                <div *ngIf="f.email.errors.pattern">Email format should be valid</div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Address <span class="asterisk">*</span></mat-label>
                                <input formControlName="address" type="text" matInput placeholder="Address" #search1
                                    (focus)="$event.target.select()">
                            </mat-form-field>
                            <div *ngIf="(submittedForm && f.address.errors) || (f.address.touched && f.address.errors)"
                                class="text-danger text-lg m-0">
                                <div *ngIf="f.address.errors.required">This field is required</div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex mt-4">
                            <button class="custom-filled-button" (click)="sendOtp()" tabIndex="1"
                                *ngIf="enableOtpField">
                                Send Otp
                            </button>
                            <button class="custom-filled-button" (click)="submitForm()" tabIndex="1"
                                *ngIf="!enableOtpField">
                                Register
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
</div>

<!-- snackbar -->
<div id="snackbar">{{ snackbarMsg }}</div>

<!-- Otp modal -->
<div class="modal fade" id="otpModal" tabindex="-1" role="dialog" aria-labelledby="otpModal" aria-hidden="true"
    data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close closeButton" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div>
                <form [formGroup]="otpForm">
                    <div class="modal-header pb-0">
                        <h3 class="text-center mt-3 mb-1">
                            Verify OTP
                        </h3>
                        <p class="resent-otp" (click)="resendOtp()">
                            Resend OTP
                        </p>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Enter OTP <span class="asterisk">*</span></mat-label>
                                    <input formControlName="otp" type="text" matInput placeholder="Enter OTP"
                                        (focus)="$event.target.select()">
                                </mat-form-field>
                                <div *ngIf="(submittedOtpForm && fOtpform.otp.errors) || (fOtpform.otp.touched && fOtpform.otp.errors)"
                                    class="text-danger text-lg m-0">
                                    <div *ngIf="fOtpform.otp.errors.required">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer pb-0">
                        <button type="button" class="custom-btn button2" (click)="verifyOtp()">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
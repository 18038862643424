<section class="mainveh_wrapper">
   <div class="container failedqutreq_info">
      <h2>Failed Quote Request Confirmation</h2>
      <div class="row ">
         <div class="col-md-7 col-lg-6">
            <span><strong>Subject:</strong>Quote Request</span>
            <label>Please contact Sue Arder with a quote for the following trip:</label>
            <p>Email:<strong>mdesimone@fmhlons.com</strong></p>
            <p>Phone:<strong>201-923-1481</strong></p>
         </div>
         <div class="col-md-5 col-lg-6">
            <img src="assets/admin/images/logo-login.png">
         </div>
      </div>
   </div>
   <div class="container">
      <div class=" emailconfirm_servtype mt-5">
         <h2>Trip Details</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Service Type:</p>
               <label class="col-md-8 ">One Way </label>
            </li>
            <li>
               <p class="col-md-4">Transfer Type:</p>
               <label class="col-md-8 ">Airport </label>
            </li>
            <li>
               <p class="col-md-4">Travel Type:</p>
               <label class="col-md-8 ">Departure </label>
            </li>
            <li>
               <p class="col-md-4">Trip Type:</p>
               <label class="col-md-8 ">Airport Pickup </label>
            </li>
            <li>
               <p class="col-md-4">Vehicle Type:</p>
               <label class="col-md-8">Wagon </label>
            </li>
            <li>
               <p class="col-md-4">Passengers:</p>
               <label class="col-md-8">2 </label>
            </li>
            <li class="mb-4">
               <p class="col-md-4">Luggage:</p>
               <label class="col-md-8">2</label>
            </li>
            <li>
               <p class="col-md-4">Pickup Date:</p>
               <label class="col-md-8">03/30/2020 | 30Mar2020 </label>
            </li>
            <li>
               <p class="col-md-4">Pickup Time:</p>
               <label class="col-md-8">10:00am | 1000 </label>
            </li>
            <li class="mt-4">
               <p class="col-md-4">Pickup Location:</p>
               <label class="col-md-8">Airport - Network International - EWR </label>
            </li>
            <li>
               <p class="col-md-4">Drop of Location:</p>
               <label class="col-md-8">City - 28 Dogwood Drive, Chester, NJ </label>
            </li>
         </ul>
      </div>
   </div>
   <!----pickup drop Details---->
   <div class="container ">
      <div class="row ">
         <div class="col-md-12  failedquotereq_pickbotom">
            <br>
            <p>Special Instructions:</p>
            <label class="p-5">Please pick in the terminal. UA Flight 3558. Arrives at 4:18 PM.</label>
         </div>
      </div>
   </div>
</section>
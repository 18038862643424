<ngx-spinner></ngx-spinner>
<div class="innerpage_wrapper">
	<!-- <div class="innerpage_topbanner">
        <h2>
            Email Confirmation
            <hr>
        </h2>
    </div> -->
	<section class="safety_wrapper">
		<div class="container">
			<div class="email-confirmation">
				<h2>Email Confirmation</h2>
				<hr>
				<img alt="" src="assets/images/email.png" />
				<p class="text-center mb-0 mt-2">{{outputMessage}}</p>
				<button (click)="navigateToSteps()" class="custom-btn savebutton mt-4">SAVE</button>
			</div>

		</div>
	</section>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-t-c',
  templateUrl: './client-t-c.component.html',
  styleUrls: ['./client-t-c.component.scss']
})
export class ClientTCComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

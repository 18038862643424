<section class="quotereq_wrapper">
   <div class="container">
      <div class="request_quoteform">
         <div class="request_quoteformtop">
            <h2>All-Inclusive
               Quote Request
            </h2>
            <p>The sevice is available but our local affiliate has not entered their lowest available rate in our Quote
               Engine.</p>
            <p>We'll be happy to search and respond with a quote ASAP!</p>
         </div>
         <form>
            <h3>One-Way Travel Quote </h3>
            <div class="row">
               <div class="col-md-12">
                  <p>Vehicle Type:<strong>Large Sedan</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Transfer Type:<strong>Airport </strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Travel Type: <strong>Departure</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Pickup Date: <strong>08/15/2020</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Pickup Time:<strong>11:45 AM 1145</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Pickup Address : <strong>Chicago Midway - MDW</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Drop-Off Address:<strong>Indianapolis IN</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>No of Passengers: <strong>1</strong> </p>
               </div>
               <div class="col-md-6">
                  <p>Luggage Count:<strong>1</strong> </p>
               </div>
            </div>
            <div class="request_quotebottom">
               <h3>Filters (Choices)</h3>
            </div>
            <div class="request_quotebottom">
               <h3>Enter Contact Info</h3>
               <div class="row">
                  <label class="col-md-6">
                     <p class="label-txt">Enter First Name </p>
                     <input type="text" class="input">
                     <div class="line-box">
                        <div class="line"></div>
                     </div>
                  </label>
                  <label class="col-md-6">
                     <p class="label-txt">Enter Last Name </p>
                     <input type="text" class="input">
                     <div class="line-box">
                        <div class="line"></div>
                     </div>
                  </label>
                  <label class="col-md-6">
                     <p class="label-txt">Enter Email Address </p>
                     <input type="text" class="input">
                     <div class="line-box">
                        <div class="line"></div>
                     </div>
                  </label>
                  <label class="col-md-6">
                     <p class="label-txt">Telephone </p>
                     <input type="text" class="input">
                     <div class="line-box">
                        <div class="line"></div>
                     </div>
                  </label>
                  <label class="col-md-12">
                     <p class="label-txt">How To Contact</p>
                     <select class=" form-control custom-select">
                        <option>Call</option>
                        <option>Email</option>
                     </select>
                     <div class="line-box">
                        <div class="line"></div>
                     </div>
                  </label>
                  <label class="col-md-12">
                     <p class="label-txt">Special Instructions </p>
                     <textarea class="input"></textarea>
                  </label>
               </div>
               <button type="submit">Submit Quote</button>
            </div>
         </form>
      </div>
   </div>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-membership-t-c',
  templateUrl: './delivery-membership-t-c.component.html',
  styleUrls: ['./delivery-membership-t-c.component.scss']
})
export class DeliveryMembershipTCComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

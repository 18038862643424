<div class="innerpage_wrapper">
	<div class="innerpage_topbanner">
		<h2>
			1-800-LIMO.COM Safety & Technology
			<hr>
		</h2>
	</div>
	<section class="safety_wrapper">
		<div class="container">
			<p><strong>1-800-LIMO.COM</strong> is dedicated to discovering new ways to make transportation services more
				convenient, reliable and productive. Whether it is our website, online reservation system, FAA
				authorized computerized flight data technology, GPS satellite vehicle tracking or DriveCam, we have
				dedicated significant financial and human resources in the development of advanced systems that today's
				business environment requires.
				<br>
				Safety, security and luxury have always been a part of the 1800-Limo experience, and we are very proud
				of our safety record. With our ongoing Defensive Driver training and Safety Programs, you can rest
				assure that the safety of our passengers is our number one concern.
			</p>
			<h3>VEHICLE TRACKING VIA GPS:</h3>
			<p>
				In addition to assisting with directions and maps, our Global Positioning Satellite system relays the
				speed, direction and exact location of our vehicles at all times.
			</p>
			<h3>THE GPS NAVIGATION SYSTEM:</h3>
			<p>
				The GPS Vehicle Navigation System provide our chauffeurs with their exact location by receiving signals
				from up to 24 Global Positioning System (GPS) satellites.
			</p>
			<h3>THIS NAVIGATION SYSTEM:</h3>
			<p> provides an unprecedented level of reference and connectivity. Not only does the system give vehicle
				locations, maps and directions, it also can find the nearest gas station, restaurant, ATM or hotel.
				Chauffeurs enter their destination and preference of options, such as fastest routes or shortest routes.
				The system automatically plots the optimum route.</p>
			<h3>DRIVE CAM:</h3>
			<p>
				Our vehicles are equipped with Drive Cams. This technology allows us to ensure safety, improve our
				driver training programs, and use real time data to give feedback. Additionally, Drive Cam assists with
				minimizing collisions and ensures that we provide you with the ultimate transportation experience.
			</p>
		</div>
		<div id="ourfleet"> </div>
	</section>
	<!----OUR CLIENTS------------->
	<section class="client_wrapper client_wrapper_desktop">
		<div class="container">
			<h2>SOME OF OUR CLIENTS
			</h2>
			<hr>
			<div class="row logo_image">
				<div class="col-2 col-md-2">
					<img src="assets/images/client8.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client9.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client12.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client17.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client19.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client2.svg" alt="client1">
				</div>
			</div>
			<div class="row logo_image">
				<div class="col-2 col-md-2">
					<img src="assets/images/client1.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client21.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client3.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client5.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client4.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client18.png" alt="client1">
				</div>
			</div>
			<div class="row logo_image">
				<div class="col-2 col-md-2">
					<img src="assets/images/client20.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client16.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client6.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client10.png" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client15.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client13.jpg" alt="client1">
				</div>
			</div>
			<div class="row logo_image">
				<div class="col-3 col-md-3"></div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client14.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client11.svg" alt="client1">
				</div>
				<div class="col-2 col-md-2">
					<img src="assets/images/client7.png" alt="client1">
				</div>
				<div class="col-3 col-md-3"></div>
			</div>

		</div>
	</section>

	<!-- OUR CLIENTS FOR MOBILE -->
	<section class="client_wrapper client_wrapper_mobile">
		<div class="container">
			<h2>SOME OF OUR CLIENTS
			</h2>
			<hr>
			<div class="client_logo owl-carousel owl-theme">
				<div class="item">
					<img src="assets/images/client8.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client9.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client13.jpg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client2.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client17.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client19.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client1.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client21.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client3.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client5.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client4.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client18.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client20.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client16.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client6.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client10.png" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client15.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client12.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client14.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client11.svg" alt="client1">
				</div>
				<div class="item">
					<img src="assets/images/client7.png" alt="client1">
				</div>
			</div>
		</div>
	</section>
</div>
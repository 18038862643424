<div class="scrollTopButton">
    <button type="button" class="btn active scrollButton" data-toggle="button" aria-pressed="true" (click)="scrollTop()" tabindex="-1">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" x="0px" y="0px"
            width="40px" height="40px">
            <defs>
                <style>
                    .cls-1 {
                        fill: #a2a3a2;
                    }

                    .cls-2 {
                        fill: #fff;
                    }

                    .cls-3 {
                        opacity: 0;
                    }
                </style>
            </defs>
            <title>arrow-up-flat</title>
            <path class="cls-1"
                d="M256,0C114.84,0,0,114.84,0,256S114.84,512,256,512,512,397.16,512,256,397.16,0,256,0Z" />
            <path class="cls-2"
                d="M335.08,219.58l-64-64a21.33,21.33,0,0,0-30.17,0l-64,64a21.33,21.33,0,0,0,30.17,30.17l27.58-27.58V384a21.33,21.33,0,1,0,42.67,0V222.17l27.58,27.58a21.33,21.33,0,0,0,30.17-30.17Z" />
            <g class="cls-3">
                <path d="M512,256c0,141.12-114.88,256-256,256V0C397.12,0,512,114.88,512,256Z" />
            </g>
        </svg>
    </button>
</div>



<div class="scrollDownButton">
    <button type="button" class="btn active scrollButton rotate-btn" 
             (click)="scrollDown()"  data-toggle="button" aria-pressed="true" tabindex="-1">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" x="0px" y="0px"
            width="40px" height="40px">
            <defs>
                <style>
                    .cls-1 {
                        fill: #a2a3a2;
                    }

                    .cls-2 {
                        fill: #fff;
                    }

                    .cls-3 {
                        opacity: 0;
                    }
                </style>
            </defs>
            <title>arrow-down-flat</title>
            <path class="cls-1"
                d="M256,0C114.84,0,0,114.84,0,256S114.84,512,256,512,512,397.16,512,256,397.16,0,256,0Z" />
            <path class="cls-2"
                d="M335.08,219.58l-64-64a21.33,21.33,0,0,0-30.17,0l-64,64a21.33,21.33,0,0,0,30.17,30.17l27.58-27.58V384a21.33,21.33,0,1,0,42.67,0V222.17l27.58,27.58a21.33,21.33,0,0,0,30.17-30.17Z" />
            <g class="cls-3">
                <path d="M512,256c0,141.12-114.88,256-256,256V0C397.12,0,512,114.88,512,256Z" />
            </g>
        </svg>
    </button>
</div>
<app-header></app-header>
<!-- AGM Map -->
<div class="container py-3">
	<div class="row">
		<div class="col-md-12">
			<button class="custom-filled-back-button"
				[routerLink]="['/admin/daily-bookings-admin']">
				Back
			</button>
		</div>
	</div>
	<div class="row mt-3">
		<div class="col-md-12">
			<div class="map-block">
				<div class="google_map">
					<div id="map" class="map"></div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>
<!-- AGM Map Ends -->
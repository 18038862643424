<div class="innerpage_wrapper">
   <div class="innerpage_topbanner">
      <h2>
         BLOG
         <hr>
      </h2>
   </div>
   <section class="safety_wrapper">
      <div class="container">
         <h3>Why drive with 1-800-LIMO.COM</h3>
         <p>Every qualified person who drives with 1-800-LIMO.COM is truly an independent contractor because of these
            most important legal factors. The first two are most important.</p>
         <ol>
            <li><strong>You Set Your Own Rates</strong> - We may recommend a minimum rate to remain competitive but
               still be able to earn a living wage. Always remember you are competing in a global free-market. Your
               appearance, your personality, your driver skills, the vehicle you drive and how clean you keep it, and
               your rates make all the difference on how much business comes your way from us. You will always see the
               same rate the client is quoted. We are totally transparent, and we will never try to deceive you or the
               client when it comes to money. You only need to add tolls or waiting time. Auto toll calculations coming
               soon. It’s all about TRUST!</li>
            <li><strong>Pick Up and Drop Off Locations</strong> - You will always see these locations when you get a new
               booking.</li>
            <li><strong>You Finalize Each Booking</strong> – When the ride is over, finalize the Total Cost and get paid
               the same week. Auto bank deposits coming soon.</li>
            <li><strong>ACCEPT or REJECT Any Booking</strong> - The only two reasons we would expect you to REJECT one
               of our bookings is that you’re busy taking care of your own client or doing a booking for another
               network. Unless you REJECT more bookings than ACCEPT, you may not be a good for us. Only join us if you
               have the excess capacity to ACCEPT most of our bookings or desire more quality bookings from us.</li>
            <li><strong>You Set Your Own Hours</strong> – Choose to work mornings, afternoons, evenings. Do not work
               more hours than legally allowed. Take frequent rest breaks and don’t burn out. You should not have to
               work part time more than 5 or 6 days a week. A typical full day schedule for a “limo” driver is no more
               than 10-12 hours with breaks in between. Figure one booking takes a minimum of 1 hour or more, from the
               time you drive to the pickup and return home or drive to your next pickup if you are lucky not to drive a
               long distance or deadhead. 8 bookings in a day for a “limo” driver is a lot and can is tiring. You cannot
               keep this pace 7 days a week!!!</li>
            <li><strong>Driver Unions</strong> - No one needs to belong to a union if you’re able to earn a living wage
               and work under fair working conditions that you control.</li>
            <li><strong>Change Rates in Real-Time</strong> – We give you the simple tools to change your rates to be
               competitive and work more efficiently.</li>
         </ol>
         <p>We Only Work With The Best Drivers</p>
         <p>Not every Taxi, Limo or Gig driver is great. 1-800-LIMO.COM wants to change that paradigm. My job is to
            attract the best drivers and keep them at their best. Gig and Taxi Drivers that deliver great customer
            services, like the average Limo Driver, will earn a living wage.</p>
         <p>Lyft and Uber did just a few good things to disrupt the taxi and livery industry, even though they did
            totally illegally, stepped on many toes, stole business from taxis and public transportation, and made
            transportation inconsistent and unsafe for consumers.</p>
         <p>Lyft and Uber are successful because they made hailing a ride convenient, but the iPhone was instrumental to
            making that happen.</p>
         <p>Lyft and Uber’s disruptiveness did open the eyes of local regulators and exposed the archaic rules of over
            regulation of the taxi and liver industry. Much needed competition improved the taxi industry. Taxi
            companies and drivers became lazy over the decades because local governments failed to enforce their own
            regulations.</p>
         <p>TNCs used disruptive and aggressive tactics to win market share; they flooded the streets with unqualified
            drivers, brought wage deflation by selling rides below cost, they continue to harm the trillion-dollar
            global industry, and city traffic has increased exponentially by offering on-demand rides. Their number one
            selling point to city regulators was that there would be less traffic. I knew differently. I also knew there
            wasn’t enough room at most airports to handle their expected increased traffic. Still a problem today!</p>
         <p>Now both companies are back peddling to correct all their failures with their drivers and traffic
            congestion.</p>
         <p>It’s my professional opinion they will both fail in the end with their current business model. They can’t
            earn a profit by charging below market rates forever and they will continually to lose drivers. Why do half
            of their drives leave within their first year and Why do they pay drivers sign up bonuses? Because working
            for them sucks. Slavery ended in 1865. You now have a better alternative network to work with.</p>
         <p>Why work for two companies that charge slave labor rates and eventually want to replace you with a robot
            car?</p>
         <p>The partial solution to reducing traffic congestion are: Free mass transportation systems, further
            deregulation of the taxi and limo industry equal to TNCs, and Shared Rides to and from areas where large
            numbers of people are going, locally and longer distances. It’s all about maximizing efficiencies and we’ll
            soon have this solution in place.</p>
         <p>1-800-LIMO.COM has never had to back pedal to fix any social or regulatory wrongs. We’ve followed every
            regulation and never stepped on toes or buy market share to grow the company at our competitors’ expense. We
            believe in honest competition. Let the market decides who’s best. We have the best safety record in the
            industry. We have zero major accidents, zero injuries, zero assaults, zero battery or worse, zero lawsuits,
            and our data has never been breached. We only work with licensed, insured and vetted drivers with a clean
            DMV record. We do our best to screen and vet our drivers.</p>
         <p>Not all Gig and Taxi drivers may join our global network. They must all operate a midsize sedan or larger
            vehicle and soon, all our Gig drivers will have to obtain a FOID card (Firearm Ownership ID). We do not
            promote gun ownership but use the FOID card as the best background check in the industry. We’ll also use our
            client’s valued feedback to keep only the best drivers on the road with us. All other unqualified drivers
            can keep working with Lyft and Uber, etc.</p>
         <p>Half of Gig drivers abandon Lyft and Uber within the first year and basically have no support driver support
            or training. 1-800-LIMO.COM has always been driver centric, so they’re able to learn how to be their best
            and be able to earn a living wage.</p>
         <p>It’s expensive to own, operate and insure a vehicle. We only want the nicest and safest vehicles picking you
            up no matter if it’s a short or long-distance ride. We do a better job of treating our drivers as small
            businesspeople. We help manage their business better than they can on their own. We are realistic what a
            driver can earn without burning out and what fair rates are required for them to make a small profit, just
            not pay their bills. This is their business, not a hobby.</p>
         <p>We are not focused on growth but being the best at what we do and that is to get you to and from any
            location on-time, safely, and at free-market, competitive rates. We never Surge Price a client.</p>
         <p>I highly recommend getting to know me and my company. I’m passionate about the transportation industry. Go
            through all the content and resources I’ve compiled. 1-800-LIMO.COM is a unique community I’m proud to have
            created. I put 17 years of hard work and diligence into the company and I’m not done yet. We’re just
            scratching the surface on the ride-sharing economy and regulations still need rewriting. We only want the
            best people working with us, to be the best in every way and on every level! Who can Sign Up and Drive -
            Taxi, Black Car, Limo, Gig (where legal), Handicap, Armored, Bus, Trolley, Antique My goal is to recruit
            500,000 of the best drivers with the nicest luxury vehicles, in the largest and busiest cities around the
            world. We do not want to the biggest network, just the best value for clients and best support for drivers.
            Not everyone can join. You must qualify with a personal security background check and meet our vehicle
            requirements.</p>
         <p>There is no cost to join. Accept or Reject Any Booking. Know the pickup and drop off location when you
            receive a new booking. Work locally from your neighborhood and where the business is.</p>
         <p>Why do 50% of Lyft and Uber drivers quit less than 1 year of driving? When Lyft and Uber offer you signing
            bonuses, there’s a reason. The work and pay is lousySet your own rates, it’s a free-market. Enter your
            minimum rate, mileage rate, city rate, airport rate, and hourly rate.</p>
         <ul>
            <li>We automatically add a 20% tip. Earn it by providing a smooth ride with great customer service. You make
               the difference.</li>
            <li>The lowest rate you can enter for a mid-size, non-luxury makes and models, is a $1.60 per mile.</li>
            <li>The minimum rate for a short trip in a non-luxury mid-size vehicle is $5.</li>
            <li>Get paid within 3-5 business days.</li>
            <li>We take a 25% commission.</li>
            <li>We are here to help you succeed so you don’t burn out.</li>
            <li>If you know a great driver, have them join us.</li>
         </ul>
         <h3>Gig Driver Requirements to Earn More</h3>
         <p>Gig drivers are welcome to join if you meet our strict requirements.</p>
         <ul>
            <li>You must have a minimum of 3 months driving experience working with Lyft, Uber a different TNC.</li>
            <li>You need to show proof of licensing, personal and vehicle</li>
            <li>You must show a 4.5 or better star rating.</li>
            <li>You must have a background check (FOID card, Checkr, TrustLine or Other)</li>
            <li>You must show proof of the minimum Gig Insurance required by your city and state.</li>
            <li>You must wear suitable clothing:</li>
            <li>No shorts</li>
            <li>No gym shoes</li>
            <li>No sandals</li>
            <li>Your vehicle must be a minimum:</li>
            <li>Luxury mid-size sedan, or SUV, Crossover, Wagon, minivan, handicap accessible or Large/Full-size SUV.
            </li>
            <li>Vehicle must meet city and state safety inspections.</li>
         </ul>
         <h3>Gig Driver Tips to Earn More</h3>
         <ul>
            <li>Own and operate a larger and newer the vehicle</li>
            <li>Minivans, mid-size SUVs, Large SUVs and handicap accessible are prefered to fit 3-4 passengers with
               luggage.</li>
            <li>Earn more with shared rides in a minivan or SUV with 2-3 individual stops.</li>
            <li>Prearranged airport transfers, longer distance and charters are where the demand and higher rates are
               greatest.</li>
            <li>In between prearranged rides, you can work more efficiently doing on-demand booking.</li>
            <li>Offer XM radio</li>
            <li>Offer in-vehicle video games</li>
            <li>Stream live video</li>
            <li>Offer snacks</li>
            <li>Keep your vehicle looking like new and well maintained</li>
            <li>Keep the exterior clean, weather permitting</li>
            <li>Keep the interior spotless</li>
            <li>Be friendly and make friendly conversation if they feel like talking.</li>
            <li>Greet each client and state your name</li>
            <li>Respect your clients</li>
            <li>Always be on-time</li>
            <li>Make it easy for the client to find you</li>
            <li>Always pickup and drop off in front of a building or place - Do not have them walk across the street to
               get into your vehicle. Assist them with opening doors and trunk.</li>
            <li>Assist with putting luggage in and taking luggage out and put curbside or in the doorway.</li>
            <li>Have charger cords</li>
            <li>Be patient with clients.</li>
         </ul>
         <h3>Prearranged Bookings - Morning Routine</h3>
         <p>Here’s my routine to insure I’m on time for my first booking every morning. Having a routine keeps me well
            balanced in my daily life too.</p>
         <ol>
            Make sure the tank is full or at least half full before ending the day.
            <li>I used to be a Boy Scout. Rule #1. Be Prepared</li>
            <li>Look over your daily schedule and read the details of your first booking, pickup time, locations,
               special instructions, etc. Follow instructions.</li>
            <li>Check the weather before turning in and set your alarm accordingly.</li>
            <li>Check Google Maps for approximate driver time to your pickup.</li>
            <li>If it’s going to snow, allow an extra 15 minutes minimum to clean and warm up the car.</li>
            <li>Get to sleep and get the rest you need for the next day.</li>
            <li>Wake up at least 45 minutes before you have to leave the house.</li>
            <li>Check Google Maps and weather again. Traffic and weather changes.</li>
            <li>Allow enough drive time and take the shortest route, not the fastest, to save mileage.</li>
            <li>Be ON-TIME everytime. Contact the client if you are going to be 1 second late with an accurate ETA from
               Google Maps.
            <li>Never give false ETAs if no GPS.</li>
            <li>A savings of just 2 miles per booking, per day can add up to 800 or more miles per year and x 10 years
               can be 80,000 miles or more.</li>
            <li>Avoid the toll road whenever possible when deadheading or have the extra time.</li>
            <li>Drive safely, courteously, and defensively. Do not speed.</li>
            <li>Notify client you’re enroute</li>
            <li>Notify client when on location - any special pickup instructions?</li>
            <li>Notify dispatch when departing pickup location</li>
            <li>Notify dispatch when dropping off at destination</li>
            <li>Finalize the booking ASAP</li>
            <li>Be on-time for next booking, or follow up on morning routine or go home and rest before next booking
            </li>
         </ol>
         <h3>My Position on Autonomous</h3>
         <h3> Driving Taxis and the Future of AI</h3>
         <p>Lyft and Uber only did two positive things when they came to market: they brought convenience and
            competition to the transportation industry. Local governments created todays taxi problems with over
            regulation and lack of oversight. Taxis become old, dirty and with low quality service. Lyft and Uber turned
            the taxi industry around and now are better than many Lyft and Uber drivers.</p>
         <p>Neither Lyft or Uber could have accomplished their massive growth without breaking all of the rules,
            stepping on the toes of transportation companies and local governments and the use of Apple’s iPhone which
            was released in 2007 which incorporated 2-way voice and data, and GPS in a hand-held computer.</p>
         <p>The negatives of Lyft and Uber outweigh the positives though.</p>
         <p>Their main selling point to city governments was less traffic and air pollution. I knew this was a false
            statement from the beginning. Airport roads are narrow enough, where were all of these new vehicles going to
            pickup and drop off? More congestion!</p>
         <p>Drivers would make money. Another lie. Their drivers are making less now than 2009. Half of those drivers
            quit within the first year. Why? Why do both offer drivers up to $1400 to join. Sounds good at first, but
            they have to complete 100’s of bookings in a short period of time to collect, creating forced labor and
            earning dollars on each ride.</p>
         <p>Read the headlines. Murder, assault, rape, inconsistencies with drivers, vehicles, surge pricing and low
            wages for drivers. Why do people still use them and how do regulators allow them to stay in business?</p>
         <h3>Lyft and Uber’s Long Term Agenda</h3>
         <ol>
            <li>Put the taxi and limo industry out of business by charging below cost rates and buying market share and
               offering people free rides, discounted rides and driver signup bonuses.</li>
            <li>Flood the streets with autonomous taxis.</li>
            <li>Eliminate millions of good paying jobs.</li>
            Go ahead and keep using Lyft and Uber and keep driving for them.
         </ol>
         <p>The same big business blah blah blah is now being sold the same way Lyft and Uber sold local governments.
            Automation and AI are good for society because it will free-up humans to enjoy life more. Not true. New jobs
            will be created to replace those lost, they also say comparing all of the jobs that were created when new
            tech came along. Here is the problem. For every automated job created, we lose four or more. Another selling
            point they bring up is that people will have to retrain for a different job, which is true, a lower paying
            job that requires less skill. They are dumbing us down with technology. How many telephone numbers do you
            remember?</p>
         <p>Automation assisting humans work more efficiently has been around for over a century but big business’
            agenda is to only kill off jobs for higher profits and government is allowing them to do it. Very few
            people, and definitely not governments, are talking about the long-term ramifications of the increasing
            threat to human jobs and its consequences. What is going to happen to people who cannot find another high
            paying job?. Most people, men especially, compare happiness and purpose with their job. What will be their
            mental state of mind and what will be the emotional consequences when people lose purpose in life? Who’s
            going to pay to deal with these new mental health issues?</p>
         <p>Who is going to pay for mass unemployment? The rich? Think again! They don’t want to pay their fair share in
            taxes now. What’s going to make them to want to pay more taxes when reality hits and their are only low wage
            jobs for the underemployed to work?</p>
         <p>Stop Big tech companies, Lyft, Uber, Waymo, Ford, GM and all of the other car manufacturers from killing
            millions of jobs now!!!</p>
         <p>Refuse to get in ANY of their vehicles and refuse to buy an autonomous vehicle in the future.</p>
         <p>The job that is lost could be yours!!</p>
      </div>
   </section>
</div>
<ngx-spinner></ngx-spinner>
<div class="subs_wrapper">
    <div>
        <div class="d-flex flex-column align-items-center heading_top">
            <span style="font-size: 32px;font-weight: 400;">
                Find the Perfect Plan for Your Needs
            </span>
            <span style="font-size: 17px;font-weight: 300;">
                Flexible monthly options that grow with your business.
            </span>
        </div>
        <!-- (click)="selectedPlan(plans?.id)" -->
        <div class="subs_div">
            <div [ngClass]="selectedPlanId == plans?.id ? 'selected_subs_content' : 'subs_content' "
                *ngFor="let plans of planData?.data">
                <div class="d-flex flex-column align-items-center mb-2"
                    style="border-bottom: 1px solid #d7d3d0; height: 12rem;">
                    <img src="../../../../assets//images/basePlan.png" *ngIf="plans?.id == 1 else premiumPlan;"
                        height="25" width="25" style="margin-bottom: 10px;" />
                    <ng-template #premiumPlan>
                        <img src="../../../../assets//images/gem.png" height="25" width="25"
                            style="margin-bottom: 10px;" />
                    </ng-template>
                    <span class="subs_planName">
                        {{plans?.product_name}}
                    </span>
                    <span class="subs_planTitle" [ngClass]="{'mb-23': plans?.id == 1}">
                        {{plans?.product_text}}
                    </span>
                    <span class="sub_planPrice">
                        $ {{plans?.product_price}} <span style="color: #000;font-weight: 400;font-size: 17px;">
                            / month</span>
                    </span>
                </div>
                <div class="sub_planDetails">
                    <div *ngFor="let category of ['features', 'advantages', 'benefits']">
                        <span class="sub_planDetails_heading">{{ category | titlecase}}</span>
                        <ul class="sub_ul">
                            <li *ngFor="let key of objectKeys(plans?.product_description[category])">
                                <img src="../../../../assets/images/Check.png" height="22" style="margin-right: 6px;" />
                                <span [innerHTML]="transform(key | titlecase)" [attr.data-bs-toggle]="'tooltip'"
                                    [attr.data-bs-placement]="'top'"
                                    [attr.title]="plans?.product_description[category][key].description">
                                </span>
                                <!-- <span [innerHTML]="transform(key | titlecase)"></span> -->
                                <!-- <p>{{ plans?.product_description[category][key].description }}</p> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="sub_planDetails">
                    <ul class="sub_ul">
                        <li *ngFor="let detail of plans?.product_description">
                            <img src="../../../../assets/images/Check.png" height="22" style="margin-right: 6px;" />
                            {{ detail }}
                        </li>
                    </ul>
                </div> -->
                <button class="custom-filled-button green-button" (click)="submitPlan(plans)"
                    style="color: white;background-color: #001cffe0;border: 1px solid #001cffe0;">
                    {{plans?.id == 1 ? 'Sign Up Now' : 'Subscribe Now'}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="custom-backdrop" [class.show]="isModalOpen"></div>
<div class="modal fade" [class.show]="isModalOpen" [style.display]="isModalOpen ? 'block' : 'none'" id="subscriber199"
    tabindex="-1" role="dialog" aria-labelledby="subscriber199" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <p class='text-danger font-weight-bolder text-2xl'>{{erroMsg}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="custom-btn resetbutton" (click)="closeModal()"
                    style="font-size: 18px;font-weight: 700;padding: 12px;">Close</button>
                <button type="button" class="custom-btn savebutton" (click)="vehicleSettings()"
                    style="font-size: 18px;font-weight: 700;padding: 12px;">Vehicle
                    Settings</button>
                <button type="button" class="custom-btn savebutton" (click)="proceedSubs(planData?.data)"
                    *ngIf="showVehicleBtn" style="font-size: 18px;font-weight: 700;padding: 12px;">Proceed</button>
            </div>
        </div>
    </div>
</div>
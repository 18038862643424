<section class="mainveh_wrapper">
   <div class="container clientebooking_info">
      <h2>Client e-Mail Confirmation</h2>
      <div class="row ">
         <div class="col-md-7 col-lg-6">
            <span><strong>Subject:</strong>New 1-800-LIMO.COM Booking</span>
            <h3>Booking #: 4585605464</h3>
         </div>
         <div class="col-md-5 col-lg-6">
            <img src="assets/admin/images/logo-login.png">
         </div>
         <div class="col-md-12">
            <p>Verify confirmation is accurate. Drivers will arrive 15 min. after all domestic and 30 min. after
               international flights to minimize waiting time to save you money. If you decide to pay with cash, you
               must ask the driver for a signed receipt. If the base rate or other information is questionable contacti
               1-800-LIMO.COM (1-800-5466-266) before the service date orcharges will apply. Waiting time, additional
               parking, extra stops and tolls are added to the Alll-Inclusive Rate</p>
         </div>
         <ul>
            <li>
               <h4>All-Inclusive ﻿Rate*</h4>
               <h4>
                  Hours <label>0</label> x Rate* <label>$0</label>
               </h4>
            </li>
            <li><label>$0</label> <label>$0</label></li>
         </ul>
      </div>
   </div>
   <div class="container">
      <div class=" emailconfirm_servtype">
         <h2>Date / Service Type</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Status:</p>
               <label class="col-md-8">Assigned </label>
            </li>
            <li>
               <p class="col-md-4">Pick Up Date:</p>
               <label class="col-md-8">03/30/2020 | 30Mar2020 </label>
            </li>
            <li>
               <p class="col-md-4">Pick Up Time:</p>
               <label class="col-md-8">10:00am | 1000 </label>
            </li>
            <li>
               <p class="col-md-4">Duration:</p>
               <label class="col-md-8">0 </label>
            </li>
            <li>
               <p class="col-md-4">Vehicle Type:</p>
               <label class="col-md-8">Wagon </label>
            </li>
            <li>
               <p class="col-md-4">Number of Passengers:</p>
               <label class="col-md-8">1 </label>
            </li>
         </ul>
         <h2>Passenger Details</h2>
         <ul class="">
            <li>
               <p class="col-md-4">Pax Name:</p>
               <label class="col-md-8">James Simpkins </label>
            </li>
            <li>
               <p class="col-md-4"># of Pax:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4"># of Luggage:</p>
               <label class="col-md-8"> </label>
            </li>
            <li>
               <p class="col-md-4">Pax Cell#:</p>
               <label class="col-md-8">817-312-6974 </label>
            </li>
            <li>
               <p class="col-md-4">Other Contact#:</p>
               <label class="col-md-8">817-312-6974 </label>
            </li>
         </ul>
      </div>
   </div>
   <!----pickup drop Details---->
   <div class="container ">
      <div class="row ">
         <div class="col-md-6 ">
            <div class="emailconfirm_pickdrop">
               <h2>Pickup</h2>
               <ul class="">
                  <li>
                     <p class="col-md-6 col-lg-4">Pickup Address: :</p>
                     <label class="col-md-6 col-lg-8">I-43, Elkhorn, WI 53121, USA</label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Airport:</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Airline Name::</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Flight Number:</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Origin Airport / City:</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
               </ul>
            </div>
         </div>
         <div class="col-md-6 ">
            <div class="emailconfirm_pickdrop">
               <h2>Final Drop Off</h2>
               <ul class="">
                  <li>
                     <p class="col-md-6 col-lg-4">Drop Address:</p>
                     <label class="col-md-6 col-lg-8">I-43, Elkhorn, WI 53121, USA</label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Airport :</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Airline Name::</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
                  <li>
                     <p class="col-md-6 col-lg-4">Flight Number:</p>
                     <label class="col-md-6 col-lg-8"> </label>
                  </li>
               </ul>
            </div>
         </div>
         <div class="col-md-12  emailconf_pickbotom">
            <p>Extra Stop # 2:</p>
            <p>Extra Stop # 3:</p>
            <p>Meet and Greet Choices: <strong>Driver - Call On Location on all meet and greet choices</strong></p>
            <br>
            <p>Special Requests:</p>
            <label>Please pick up at the Center for Translational Research and Education Building (green/glass bldg)
               next to Stritch School of Medicine.</label>
         </div>
      </div>
   </div>
   <!----Driver Details---->
   <div class="container quotebot_preview">
      <h2>Driver Details</h2>
      <div class="row">
         <div class="col-md-4 col-lg-3">
            <img src="assets/admin/images/driver_head.png">
         </div>
         <div class="col-md-8 col-lg-9">
            <ul class="">
               <li>
                  <p class="col-md-4">Driver Name:</p>
                  <label class="col-md-8">Dale A</label>
               </li>
               <li>
                  <p class="col-md-4">Gender:</p>
                  <label class="col-md-8">Male </label>
               </li>
               <li>
                  <p class="col-md-4">Starting Rating:</p>
                  <label class="col-md-8">N/A </label>
               </li>
               <li>
                  <p class="col-md-4">Background:</p>
                  <label class="col-md-8">N/A</label>
               </li>
               <li>
                  <p class="col-md-4">Dress:</p>
                  <label class="col-md-8">Suit and Tie</label>
               </li>
               <li>
                  <p class="col-md-4">Languages :</p>
                  <label class="col-md-8">English</label>
               </li>
               <li>
                  <p class="col-md-4">Years of Experience:</p>
                  <label class="col-md-8">6</label>
               </li>
               <li>
                  <p class="col-md-4">Ins Limits</p>
                  <label class="col-md-8"></label>
               </li>
            </ul>
         </div>
      </div>
   </div>
   <!----Vehicle Type---->
   <div class="container quotebot_preview">
      <h2>Vehicle Type</h2>
      <div class="row">
         <div class="col-md-4 col-lg-3">
            <img class="responsive-img" src="assets/admin/images/step4_veh1.png">
         </div>
         <div class="col-md-8 col-lg-9">
            <ul class="">
               <li>
                  <p class="col-md-4">Vehicle Type:</p>
                  <label class="col-md-8">Mid Size Sedan</label>
               </li>
               <li>
                  <p class="col-md-4">Vehicle Make:</p>
                  <label class="col-md-8">2020 </label>
               </li>
               <li>
                  <p class="col-md-4">Vehicle Model:</p>
                  <label class="col-md-8">Lexus ES 300 </label>
               </li>
               <li>
                  <p class="col-md-4">Vehicle Color:</p>
                  <label class="col-md-8">Black</label>
               </li>
               <li>
                  <p class="col-md-4">Licence Plate#:</p>
                  <label class="col-md-8"></label>
               </li>
            </ul>
         </div>
      </div>
   </div>
   <!----AMENITIES Details---->
   <div class="container amenites_wrapper">
      <h2>Amenities </h2>
      <div class="amenites_wrapperin">
         <label>Amenities</label>
         <ul>
            <li>Luxury Model</li>
            <li>EV/Hybrid </li>
            <li>Jet Sprinter </li>
            <li>4-Wheel Drive </li>
            <li>Excutive </li>
            <li>Forward Facing </li>
            <li>Captain's Chair </li>
            <li>Warp Around- Limo </li>
            <li>Deluxe Touring </li>
         </ul>
      </div>
   </div>
   <!----pickup drop Details---->
   <div class="container ">
      <div class="row ">
         <!----------NOTE--------->
         <div class="col-md-12 emailconfm_notes">
            <h2>Policy and Procedure</h2>
            <p>Please read our confirmations carefully for accuracy, company policies and procedures for flawless
               chauffeured transportation. Base rates are quoted upon availability. Disregard the $ sign for
               International bookings - rates are quoted in country currency. Check emails before departure for changes
               to local affiliate telephone number, rate changes or other alerts. Please be aware that the local
               affiliate telephone number may change due to sudden changes in fleet scheduling, airline or weather
               delays. You must call our local affiliate for airport curbside pickup or if you cannot find your
               chauffeur for any reason. Call 1 800 LIMO.COM (1 800 5466.266) immediately if you cannot contact the
               local affiliate, it the affiliate does not have your reservation, or to cancel your booking with at least
               a 2 hour minimum notice depending upon the type of vehicle, day of the week or charter. Some of our
               affiliates charge a 100% no show fee with less than 24 hours notice. You may call the local affiliate
               (see Local Affiliate Tel Number under Confirmation Code) anytime to reconfirm or change your reservation
               details. You must call 1 800 LIMO.COM if you cannot connect with or confirm this reservation with the
               local affiliate, if you need an updated local affiliate telephone number or if the airline lost your
               luggage. If you get a voice mail prompt, leave a message with your name and cell phone number - we will
               return your call ASAP. Do not leave your pick up location without calling 1 800 5466.266 or leaving a
               message, you may be charged for a "NO-SHOW" and/or waiting time. If the driver cannot make contact with
               the passenger, your driver will wait up to one hour and will then be released with a “no-show” charge.
               Not all US airports allow baggage claim pickups. International airport pickups are at outside customs or
               transportation/limo booths. Some US airports are also at these booths. See Special Requests or
               Instructions on confirmation. Pier arrivals: Call local affiliate when debarking for pickup location
               instructions. Airport and dockside pick up regulations may change without notice. Passenger waiting time
               will be kept to an acceptable minimum. “Confirmed” status - waiting for affiliate to Accept or Reject the
               booking. “Assigned” status - affiliate has accepted the booking at the quoted base rate. 1 800 LIMO.COM
               does its best to offer you the lowest available rate (dollar averages your transportation costs) and
               dispatches the newest vehicles and best drivers to insure the best service. Vehicles may be substituted
               and rates may be slightly lower or higher than first quoted due to fleet schedule changes, bad weather,
               flight delays and conditions beyond our control. We will do our best to notify you of any changes before
               the travel date. Call us immediately if you have any concerns or are dissatisfied in any way with our
               local dispatcher, vehicle condition, driver, or overall service. We will do our best to correct the
               situation at that moment.</p>
            <p class="text-success mt-3"> To minimize waiting time charges - drivers will be on location 15 min. after
               domestic and 30 min. after international flights.</p>
            <p class="text-danger">Do not pay the driver. Hand tip extra at your own discretion. If you pay the driver
               cash, always ask for a receipt.</p>
            <label>*Tolls, waiting time, extra stop and taxes may apply above the All-Inclusive Rate
               *Charter/tours Hours may be longer depending on final drop off time. The minimum number of hours is
               charged for an earlier end time.
               You may speak with the client if they call and make any changes. Contact Global Dispatch with any changes
               to amend any reservation on places, time and rate changes. Be flexible with your rates and cancellation
               policies to keep our clients coming back for more great service.
            </label>
            <p>Thank you, </p>
            <p>1-800-LIMO.COM</p>
            <p>Phone: 1-800-5466-266</p>
            <p>International Phone Number: (000)0017082056607</p>
            <p>info@1800limo.com</p>
            <p><strong>Note:<a href="#">Click</a>to Open and Read Below – Company Policies and Procedures </strong></p>
         </div>
      </div>
   </div>
</section>
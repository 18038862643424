import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photo-instructions',
  templateUrl: './photo-instructions.component.html',
  styleUrls: ['./photo-instructions.component.scss']
})
export class PhotoInstructionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
